import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AdItem } from "./PropertyPostModal";
import { ChartBarIcon, TrophyIcon } from "@heroicons/react/24/solid";
interface InfoModalProps {
  onClick: () => void;
  paragraphs: string[];
  typeOfPackage: string;
}
const PackageInformationModal: React.FC<InfoModalProps> = ({
  onClick,
  paragraphs,
  typeOfPackage,
}) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-neutral-800/70"
      onClick={onClick}
    >
      <div className="bg-white p-8 rounded-lg shadow-md w-96 flex justify-center flex-col">
        <div className="w-full">
          {paragraphs.map((p: string, index: number) => (
            <div key={index}>
              <div className="w-full border-b border-neutral-200 dark:border-neutral-800 mb-2"></div>
              <div className="flex items-center mb-4">
                {index === 0 ? (
                  <TrophyIcon className="w-6 mr-2" />
                ) : (
                  <ChartBarIcon className="w-6 mr-2" />
                )}
                <p className="w-full">{p}</p>
              </div>
            </div>
          ))}
        </div>
        <ButtonPrimary className="mt-4" onClick={onClick}>
          Okay
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default PackageInformationModal;
