import React, { useState } from "react";

const placeholder =
  "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon. It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies. It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.";

interface FaqItem {
  id: string;
  question: string;
  answer: string;
}

const faqItems: FaqItem[] = [
  {
    id: "p-acquisition",
    question: "Property Acquisition Without Trying",
    answer:
      "It's highly recommended that prospective buyers personally visit the property or delegate a trusted representative to conduct an on-site assessment. While photographs offer a glimpse, experiencing the property firsthand is pivotal in gauging its compatibility with your preferences and requirements. Renting the property allows for a comprehensive evaluation, ensuring alignment with your expectations.",
  },
  {
    id: "arranging-a-visit",
    question: "Arranging a Visit",
    answer:
      "To arrange a visit, either book a stay directly or reach out to the property seller for scheduling. At TryBuy.club, we facilitate prospective buyers by offering the unique opportunity to temporarily reside in properties of interest prior to finalizing a purchase. This immersive experience aids in making informed decisions about your future home.",
  },
  {
    id: "pets",
    question: "Pet Policy Inquiry",
    answer:
      "The pet policy is subject to the terms outlined in each individual listing. Prior notification to the property owner regarding any pets is essential before occupancy.",
  },
  {
    id: "dmg",
    question: "Handling Property Damage",
    answer:
      "In the event of accidental damage, prompt communication with the property owner is crucial. Rest assured, mishaps occur, and transparency is key. You will be expected to assume responsibility for rectifying any damage incurred. Proactive reporting prevents escalation and safeguards your deposit.",
  },
];

function FaqSimple() {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const handleClick = (value: string) => {
    setActiveItem(activeItem === value ? null : value);
  };

  return (
    <div className="max-w-lg mx-auto my-8 z-30 relative">
      <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>

      <div>
        {faqItems.map((item) => (
          <div key={item.id} className="mb-4 cursor-pointer" onClick={() => handleClick(item.id)}>
            <button className="flex cursor-pointer justify-between w-full bg-white rounded-lg p-4 shadow-md hover:shadow-lg focus:outline-none focus:ring focus:ring-blue-300">
              <span className="text-lg">{item.question}</span>
              <span>{activeItem === item.id ? "-" : "+"}</span>
            </button>
            {activeItem === item.id && (
              <div className="bg-white rounded-lg p-4 shadow-md mt-5 animate-appear">
                <p>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FaqSimple;
