import React, { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { TbBrandBooking } from "react-icons/tb";
import LikeSaveBtns from "components/LikeSaveBtns";
import { redirect, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useGalleryModal from "hooks/useGalleryModal";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { amenities } from "components/Modals/PropertyPostModal";
import { User } from "Types/User";
import getUser from "actions/getUser";
import { IMAGE_URL } from "config/config";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import SectionDateRange from "containers/ListingDetailPage/SectionDateRange";
import StayDatesRangeInput, {
  Reservation,
} from "containers/ListingDetailPage/listing-stay-detail/StayDatesRangeInput";
import GuestsInput from "containers/ListingDetailPage/listing-stay-detail/GuestsInput";
import getListingById from "actions/getListingById";
import MapListing from "components/MapListing";
import getReservationsForListing from "actions/getReservationsForListing";
import { UserContext } from "UserContext";
import usePropertyEditModal from "hooks/usePropertyEditModal";
import { FaAirbnb } from "react-icons/fa6";
import { Listing } from "Types/Listing";
import { MdEmail, MdLocalPhone } from "react-icons/md";
export const formatPrice = (price: number) => {
  let priceString = price.toString();

  let priceParts = priceString.split(".");
  let integerPart = priceParts[0];
  let decimalPart = priceParts[1] ? " " + priceParts[1].replace(/0+$/, "") : "";

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return integerPart + decimalPart;
};

const StayDetailPageContainer: FC<{}> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const queryParams = new URLSearchParams(location.search);
  const startDateParam = queryParams.get("checkIn");
  const endDateParam = queryParams.get("checkOut");
  const adultGuestParam = queryParams.get("adultGuestCount");
  const childrenGuestsParam = queryParams.get("childrenGuestCount");
  const infantGuestsParam = queryParams.get("infantGuestCount");
  const parsedId = id?.split("-")[0];
  const calculateNumberOfNights = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const timeDifference = endDate.getTime() - startDate.getTime();
      const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return numberOfNights;
    }
    return 0;
  };

  const [listing, setListing] = useState<Listing>({
    id: 0,
    ownerId: 0,
    name: "",
    category: "",
    pricePerNight: 0,
    listingCat: "",
    categorySlug: "",
    location: null,
    city: null,
    address: "",
    roomCount: 1,
    galleryImgs: "",
    bathroomCount: 1,
    guestCount: 1,
    bedCount: 1,
    images: "",
    price: 1,
    propertyPrice: 1,
    priceNegotiable: false,
    description: "",
    size: "",
    locationCoords: [1, 1],
    lat: 1,
    lng: 1,
    amenities: [],
    airbnb: "",
    booking: "",
    other: "",
    priceUnit: "",
    sizeUnit: "",
    packageId: "",
  });
  const [reservations, setReservations] = useState<Reservation[]>([
    {
      id: 1,
      startDate: new Date(),
      endDate: new Date(),
      listingId: 1,
      renterId: 1,
      pricePerNight: 1,
    },
  ]);
  const propertyEditModal = usePropertyEditModal();
  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [startDateBooking, setStartDate] = useState<Date | null>(
    new Date(formatDate(new Date(startDateParam || Date.now())))
  );
  const [endDateBooking, setEndDate] = useState<Date | null>(
    new Date(formatDate(new Date(endDateParam || Date.now())))
  );
  const [adultGuestCount, setAdultGuestCount] = useState<number>(Number(adultGuestParam));
  const [childrenGuestCount, setChildrenGuestCount] = useState<number>(Number(childrenGuestsParam));
  const [infantGuestCount, setInfantGuestCount] = useState<number>(Number(infantGuestsParam));
  const [url, setUrl] = useState<string>("");
  const [searchParams] = useSearchParams();
  const router = useNavigate();
  const updateURL = async () => {
    const currentQuery = Object.fromEntries(searchParams);
    const updatedQuery = {
      ...currentQuery,
      checkIn: formatDate(new Date(startDateBooking || Date.now())) || "",
      checkOut: formatDate(new Date(endDateBooking || Date.now())) || "",
      adultGuestCount: adultGuestCount.toString(),
      childrenGuestCount: childrenGuestCount.toString(),
      infantGuestCount: infantGuestCount.toString(),
      totalNights: numberOfNights.toString(),
      pricePerNight: listing.pricePerNight.toString(),
      currency: listing.priceUnit.toString() || "",
    };

    const paramsString = new URLSearchParams(updatedQuery).toString();
    const url = paramsString ? `?${paramsString}` : "/";
    router(url);
    setUrl(url);
  };
  useEffect(() => {
    updateURL();
  }, [startDateBooking, endDateBooking, adultGuestCount, childrenGuestCount, infantGuestCount]);
  const [owner, setOwner] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const listingData = await getListingById(Number(parsedId));
      //@ts-ignore
      setListing(
        listingData || {
          id: 0,
          ownerId: 0,
          name: "",
          category: "",
          pricePerNight: 0,
          listingCat: "",
          categorySlug: "",
          location: null,
          city: null,
          address: "",
          roomCount: 1,
          bathroomCount: 1,
          guestCount: 1,
          bedCount: 1,
          images: "",
          price: 1,
          propertyPrice: 1,
          priceNegotiable: false,
          description: "",
          size: "",
          locationCoords: [1, 1],
          lat: 1,
          lng: 1,
          galleryImgs: "",
          amenities: [],
          airbnb: "",
          booking: "",
          other: "",
          priceUnit: "",
          sizeUnit: "",
          packageId: "",
        }
      );
      const ownerData = await getUser(Number(listingData?.ownerId));
      setOwner(ownerData);
      const reservationsData = await getReservationsForListing(Number(listingData?.id));
      if (reservationsData !== null) {
        setReservations(reservationsData);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 50);
    };

    fetchData();
  }, []);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }
  const handleChangeOfGuests = (
    totalGuests: number,
    adultGuests: number,
    childrenGuests: number,
    infantGuests: number
  ) => {
    setAdultGuestCount(adultGuests);
    setChildrenGuestCount(childrenGuests);
    setInfantGuestCount(infantGuests);
  };
  const [numberOfNights, setNumberOfNights] = useState<number>(0);

  const handleNumberOfNightsChange = (
    newNumberOfNights: number,
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setNumberOfNights(newNumberOfNights);
    setStartDate(startDate);
    setEndDate(endDate);
  };
  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }
  const galleryModal = useGalleryModal();
  const handleOpenModalImageGallery = () => {
    //@ts-ignore
    galleryModal.onOpen();
  };
  const renderSection1 = () => {
    const parsedCity = listing.city as unknown as string;

    return isLoading ? (
      <div className="listingSection__wrap !space-y-6 w-full animate-pulse h-[330px] rounded-xl"></div>
    ) : (
      <div className="listingSection__wrap !space-y-6 animate-appear">
        {/* 1 */}
        <div className="flex justify-between items-center">
          {isLoading ? (
            <div className="w-24 h-5 rounded-lg animate-pulse"></div>
          ) : (
            <Badge name={listing.listingCat} className="animate-appear" />
          )}
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        {isLoading ? (
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold animate-pulse h-10 w-40 rounded-lg"></h2>
        ) : (
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold animate-appear">
            {listing.name}
          </h2>
        )}

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {listing.address}, {parsedCity}
            </span>
          </span>
        </div>

        {/* 4 */}
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate(`/profile/${owner?.name}?id=${owner?.id}`);
          }}
        >
          <Avatar userName={owner?.name} sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {owner?.name}
            </span>
          </span>
        </div>
        <div className="flex flex-col ml-4">
          <div className="flex items-center mt-2">
            <MdLocalPhone size={20} className="text-gray-600 dark:text-gray-300" />
            <span className="ml-2 text-neutral-900 dark:text-neutral-200 font-medium">
              {owner?.tel}
            </span>
          </div>
          <div className="flex items-center mt-1">
            <MdEmail size={20} className="text-gray-600 dark:text-gray-300" />
            <span className="ml-2 text-neutral-900 dark:text-neutral-200 font-medium">
              {owner?.email}
            </span>
          </div>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {listing.bedCount} <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {listing.bathroomCount} <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              {listing.roomCount} <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>

          <div className="flex items-center space-x-3">
            <i className=" las la-expand-arrows-alt text-2xl"></i>
            <span className=" ">
              {listing.size} <span className="hidden sm:inline-block">{listing.sizeUnit}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };
  const renderSection14 = () => {
    return isLoading ? (
      <div className="listingSection__wrap !space-y-6 w-full animate-pulse h-[330px] rounded-xl"></div>
    ) : (
      <div className="listingSection__wrap !space-y-6 animate-appear">
        <h2 className="text-2xl font-semibold">Book with other platforms</h2>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3">
            {listing.airbnb.length > 2 && (
              <ButtonSecondary
                targetBlank
                href={listing.airbnb}
                className="disabled:cursor-not-allowed"
              >
                <FaAirbnb size={20} />{" "}
                <span className="text-l md:text-xl ml-0 md:ml-2">Airbnb</span>
              </ButtonSecondary>
            )}
            {listing.booking.length > 2 && (
              <ButtonSecondary
                targetBlank
                href={listing.booking}
                onClick={() => {}}
                className="disabled:cursor-not-allowed"
              >
                <TbBrandBooking size={20} />{" "}
                <span className="text-l md:text-xl ml-0 md:ml-2">Booking</span>
              </ButtonSecondary>
            )}
            {listing.other.length > 2 && (
              <ButtonSecondary
                targetBlank
                href={listing.other}
                onClick={() => {}}
                className="disabled:cursor-not-allowed"
              >
                <span className="text-l md:text-xl">Other</span>
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    const desc = listing.description.split("\n");
    return isLoading ? (
      <div className="listingSection__wrap h-[250px] animate-pulse"></div>
    ) : (
      <div className="listingSection__wrap animate-appear">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {desc.map((paragraph, i) => (
            <div key={i}>
              <span>{paragraph}</span>
              <br />
              <br />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return isLoading ? (
      <div className="listingSection__wrap h-[300px] animate-pulse"></div>
    ) : (
      <div className="listingSection__wrap animate-appear">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {listing.amenities
            //@ts-ignore
            .split(",")
            .map((amenityId: number) =>
              amenities.find((amenity) => amenity.id === Number(amenityId))
            )
            //@ts-ignore
            .map((item) => (
              <div key={item?.id} className="flex items-center space-x-3">
                <item.icon size={32} />
                <span>{item?.label}</span>
              </div>
            ))}
        </div>

        {/* ----- */}
        {/* <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>View more 20 amenities</ButtonSecondary>
        </div>
        {renderMotalAmenities()} */}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {/* {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i className={`text-4xl text-neutral-6000 las ${item.icon}`}></i>
                        <span>{item.name}</span>
                      </div>
                    ))} */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection5 = () => {
    return isLoading ? (
      <div className="listingSection__wrap h-[500px] animate-pulse"></div>
    ) : (
      <div
        className="listingSection__wrap animate-appear"
        onClick={() => {
          navigate(`/profile/${owner?.name}?id=${owner?.id}`);
        }}
      >
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            userName={owner?.name}
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {owner?.name}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in {owner?.createdOn}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href={`/profile/${owner?.name}?id=${owner?.id}`}>
            See host profile
          </ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    const parsedCity = listing.city as unknown as string;

    return isLoading ? (
      <div className="listingSection__wrap h-[300px] animate-pulse"></div>
    ) : (
      <div className="listingSection__wrap animate-appear">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {listing.address}, {parsedCity}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            {!isLoading ? <MapListing center={[listing.lat, listing.lng]} /> : ""}
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return isLoading ? (
      <div className="listingSection__wrap h-[500px] animate-pulse"></div>
    ) : (
      <div className="listingSection__wrap animate-appear">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room within 48 hours after
            successful booking and 14 days before the check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50% refund of the total
            amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and environment green and clean
                by not littering, not using stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return isLoading ? (
      <div className="listingSectionSidebar__wrap shadow-xl animate-pulse h-[600px]"></div>
    ) : (
      <div className="listingSectionSidebar__wrap shadow-xl animate-appear">
        {/* PRICE */}
        <div className="flex justify-between flex-col">
          <div className="flex items-center mb-4">
            <span className="text-3xl font-semibold text-blue-600">
              {formatPrice(Number(listing.pricePerNight))} {listing.priceUnit == "USD" ? "$" : "€"}
            </span>
            <span className="ml-2">
              <span className="px-2 py-1 bg-blue-100 text-blue-800 font-semibold rounded-full">
                PER NIGHT
              </span>
            </span>
          </div>

          {/* Buying price for the property */}
          <div className="flex items-center">
            <span className="text-2xl font-semibold text-purple-600">
              {formatPrice(Number(listing.price))} {listing.priceUnit == "USD" ? "$" : "€"}
            </span>
            <span className="ml-2">
              <span className="px-2 py-1 bg-purple-100 text-purple-800 font-semibold rounded-full">
                BUYING PRICE
              </span>
            </span>
          </div>
          {/* <StartRating /> */}
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            onNumberOfNightsChange={handleNumberOfNightsChange}
            reservations={reservations}
            checkIn={new Date(formatDate(new Date(startDateParam || Date.now())))}
            checkOut={new Date(formatDate(new Date(endDateParam || Date.now())))}
            className="flex-1 z-[11]"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="flex-1"
            handleChangeOfGuests={handleChangeOfGuests}
            adultGuestParam={Number(adultGuestParam)}
            childrenGuestsParam={Number(childrenGuestsParam)}
            infantGuestsParam={Number(infantGuestsParam)}
          />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {formatPrice(Number(listing.pricePerNight))} {listing.priceUnit == "USD" ? "$" : "€"}{" "}
              x {numberOfNights} {numberOfNights > 1 ? "nights" : "night"}
            </span>
            <span>
              {formatPrice(Number(listing.pricePerNight) * numberOfNights)}{" "}
              {listing.priceUnit == "USD" ? "$" : "€"}
            </span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {formatPrice(Number(listing.pricePerNight) * numberOfNights)}{" "}
              {listing.priceUnit == "USD" ? "$" : "€"}
            </span>
          </div>
        </div>

        {/* SUBMIT */}
        {user?.id !== owner?.id ? (
          <ButtonPrimary
            href={``}
            onClick={() => {
              navigate(`/book-listing/${listing.id}/${url}`);
            }}
            className="disabled:cursor-not-allowed"
            disabled={
              calculateNumberOfNights(
                new Date(startDateParam || Date.now()),
                new Date(endDateBooking || Date.now())
              ) < 1 ||
              Number(adultGuestCount) + Number(childrenGuestCount) + Number(infantGuestCount) === 0
            }
          >
            Reserve
          </ButtonPrimary>
        ) : (
          <ButtonPrimary
            href={``}
            onClick={() => {
              propertyEditModal.setListingId(listing.id);
              propertyEditModal.onOpen();
            }}
            className="disabled:cursor-not-allowed"
          >
            Edit Listing
          </ButtonPrimary>
        )}
      </div>
    );
  };
  return (
    <div className="nc-ListingStayDetailPage mt-10">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            {isLoading ? (
              <div className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full animate-pulse"></div>
            ) : (
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full animate-appear"
                src={`${IMAGE_URL}${listing.images?.split(",")[0]}`}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
            )}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {isLoading
            ? listing.images?.split(",").map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? "hidden sm:block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 animate-pulse"></div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))
            : listing.images?.split(",").map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? "hidden sm:block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5 animate-appear">
                    <img
                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                      src={IMAGE_URL + item || ""}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

          {isLoading ? (
            <></>
          ) : (
            <button
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="w-5 h-5" />

              <span className="ml-2 text-neutral-800 text-sm font-medium">Show all photos</span>
            </button>
          )}
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <ListingImageGallery
          //@ts-ignore
          isShowModal={galleryModal.isOpen}
          //@ts-ignore
          onClose={galleryModal.onClose}
          //@ts-ignore
          images={listing.images.split(",").map((item) => {
            return {
              id: 1,
              url: IMAGE_URL + item,
            };
          })}
        />
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {listing.airbnb || listing.booking || listing.other ? renderSection14() : ""}
          {renderSection2()}
          {renderSection3()}
          {!isLoading ? (
            <SectionDateRange
              reservations={reservations}
              changedDatesAvCalendar={(checkIn, checkOut) => {
                // setStartDate(new Date(formatDate(new Date(checkIn || Date.now()))));
                // setEndDate(new Date(formatDate(new Date(checkOut || Date.now()))));
              }}
            />
          ) : (
            <></>
          )}
          {renderSection5()}
          {renderSection7()}
          {/* {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
