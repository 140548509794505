import { BookListingProvider } from "BookListingContext";
import UserContextProvider from "UserContext";
import axios from "axios";
import { IS_PRODUCTION } from "config/config";
import AccountPage from "containers/AccountPage/AccountPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import MyListingsPage from "containers/AuthorPage/MyListingsPage";
import PageBuyerGuides from "containers/PageAbout/BuyerGuidesPage";
import ComingSoonPage from "containers/PageAbout/ComingSoonPage";
import PageJoinUs from "containers/PageAbout/JoinUsAsPartnersPage";
import PageAbout from "containers/PageAbout/PageAbout";
import FaqPage from "containers/PageAbout/PageFaq";
import PrivacyPolicy from "containers/PageAbout/PrivacyPolicyPage";
import PageSecurityTips from "containers/PageAbout/SecurityTipsPage";
import PageSellerGuides from "containers/PageAbout/SellerGuidesPage";
import TermsPage from "containers/PageAbout/TermsPage";
import PageTrainingResources from "containers/PageAbout/TrainingResourcesPage";
import PageContact from "containers/PageContact/PageContact";
import PayPage from "containers/PayPage/PayPage";
import MainLayout from "layouts/MainLayout";
import CheckOutPagePageMain from "pages/BookListingPage";
import BookingConfirmation from "pages/BookingConfirmation";
import HomePage from "pages/HomePage";
import ListingStayDetailPage from "pages/ListingStayDetailPage";
import ListingMap from "pages/ListingsMap";
import React from "react";
import { Route, Routes } from "react-router-dom";
axios.defaults.baseURL = IS_PRODUCTION ? "https://api.trybuy.club" : "http://localhost:3001";
function App() {
  return (
    <UserContextProvider>
      <BookListingProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/listings/:id" element={<ListingStayDetailPage />} />
            <Route path="/book-listing/:listingId" element={<CheckOutPagePageMain />} />
            <Route path="/book-listing/:listingId/confirmation" element={<BookingConfirmation />} />
            <Route path="/listings" element={<ListingMap />} />
            <Route path="/seller-guides" element={<PageSellerGuides />} />
            <Route path="/security-tips" element={<PageSecurityTips />} />
            <Route path="/training-resources" element={<PageTrainingResources />} />
            <Route path="/buyer-guides" element={<PageBuyerGuides />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/about" element={<PageAbout />} />
            <Route path="/contacts" element={<PageContact />} />
            <Route path="/join-us-as-partners" element={<PageJoinUs />} />
            <Route path="/profile/:name" element={<AuthorPage />} />
            <Route path="/coming-soon" element={<ComingSoonPage />} />
            <Route path="/my-listings" element={<MyListingsPage />} />
            {/* <Route path="/profile/:name/" element={<AuthorPage />} />
        <Route path="/login" element={<PageLogin />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/account-socials" element={<AccountSocials />} />
        <Route path="/account-password" element={<AccountPass />} />
        <Route path="/add-listing" element={<PropertyWizard />} />
        <Route path="/listing/details/preview" element={<ListingStayDetailPagePreview />} />
        <Route path="/listings" element={<ListingStayMapPage />} />
        <Route path="/listings/listing/:slug/:id" element={<ListingStayDetailPage />} /> */}
          </Route>
        </Routes>
      </BookListingProvider>
    </UserContextProvider>
  );
}

export default App;
