import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import getListings from "actions/getListings";
import { useReducedMotionConfig } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StayDataType } from "data/types";
import { Listing } from "Types/Listing";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import L, { LatLngTuple } from "leaflet";
import ReactDOMServer from "react-dom/server";
import StayCard from "components/StayCard/StayCard";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StayCardPopup from "components/StayCard/StayCardPopup";
import SectionFounder from "containers/PageAbout/SectionFounder";
import Heading from "components/Heading";
const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {
  adultGuests: number;
  childrenGuests: number;
  infantGuests: number;
  country: string;
  state: string;
  startDate: Date;
  endDate: Date;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  adultGuests,
  childrenGuests,
  infantGuests,
  country,
  state,
  startDate,
  endDate,
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const mapContainerRef = useRef(null);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const [loadedListings, setLoadedListings] = useState(5);
  const [loadedCards, setLoadedCards] = useState(0);

  const [minPrice, setMinPrice] = useState(Number(searchParams.get("minPrice")));
  const [maxPrice, setMaxPrice] = useState(Number(searchParams.get("maxPrice")));
  const [lat, setLat] = useState(Number(searchParams.get("lat")));
  const [lng, setLng] = useState(Number(searchParams.get("lng")));
  const [minSize, setMinSize] = useState(Number(searchParams.get("minSize")));
  const [maxSize, setMaxSize] = useState(Number(searchParams.get("maxSize")));
  const [maxSizeParam, setMaxSizeParam] = useState(Number(0));
  const minPricePar = searchParams.get("minPrice");
  const [minSizeParam, setMinSizeParam] = useState(Number(0));
  const [typeOfProperty, setTypeOfProperty] = useState(searchParams.get("typeOfProperty"));
  const containerRef = useRef(null);
  const navigate = useNavigate();
  async function fetchListings(limit: number) {
    try {
      const listingsData = await getListings(
        minPrice,
        maxPrice,
        Number(minSize),
        Number(maxSize),
        limit,
        adultGuests,
        childrenGuests,
        infantGuests,
        country,
        state,
        startDate,
        endDate,
        lat,
        lng,
        typeOfProperty || ""
      );
      if (listingsData?.data.properties.length >= 1) {
        setTimeout(() => {
          setIsLoaded(true);
        }, 100);

        setListings(listingsData?.data.properties);
        setMaxSizeParam(listingsData?.data.additionalInfo.maxSize);
        setMinSizeParam(listingsData?.data.additionalInfo.minSize);
      } else {
        setListings([]);
      }
    } catch (error) {
      setListings([]);
    }
  }

  useEffect(() => {
    fetchListings(loadedListings);
  }, [loadedListings]);

  useEffect(() => {
    if (isLoaded) {
      const interval = setInterval(() => {
        setLoadedCards((prevLoaded) => {
          const nextLoaded = prevLoaded + 1;
          return nextLoaded > listings.length ? listings.length : nextLoaded;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [isLoaded, listings]);
  const fetchMoreListings = () => {
    if (
      containerRef.current &&
      //@ts-ignore
      window.innerHeight + window.scrollY >= containerRef.current.offsetHeight
    ) {
      setLoadedListings((value) => Number(value) + 10);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", fetchMoreListings);
    return () => {
      window.removeEventListener("scroll", fetchMoreListings);
    };
  }, [loadedListings, listings]);
  //@ts-ignore
  const ListingMarker = ({ listing }) => {
    const markerRef = useRef(null);
    const map = useMap();

    useEffect(() => {
      const { lat, lng, galleryImgs, name, id } = listing;
      const markerElement = L.marker([lat, lng]);
      //@ts-ignore
      const htmlC = ReactDOMServer.renderToString(<StayCardPopup data={listing} />);
      markerElement.addTo(map);
      //@ts-ignore
      markerRef.current = markerElement;
      //@ts-ignore
      markerElement.bindPopup(htmlC);
      const markerDOMElement = markerElement.getElement();
      if (markerDOMElement) {
        if (id === currentHoverID) {
          markerDOMElement.style.opacity = "0.5";
        }
      }
      //@ts-ignore
      markerElement.on("mouseover", function (e) {
        //@ts-ignore
        this.openPopup();
      });
      //@ts-ignore
      return () => {
        if (markerRef.current) {
          //@ts-ignore
          markerRef.current.remove();
        }
      };
    }, [listing, map]);

    return null;
  };
  const [isOpenMoreFilter, setIsOpenMoreFilter] = useState(false);

  const heading = searchParams.get("location");
  const headingState = searchParams.get("state");
  if (listings.length < 1) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Heading title={`No listings found with the current search criteria`} subtitle="" />
        <p className="text-lg mt-4 text-neutral-700">Please try adjusting your search criteria.</p>
      </div>
    );
  }
  return (
    <div>
      <div className="relative flex min-h-screen">
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2
            heading={`${heading} ${headingState ? "," : ""} ${headingState ? headingState : ""}`}
            listingsCount={listings.length}
          />
          <div className="mb-8 lg:mb-11">
            {/* <TabFilters
              maxSizeParam={1000}
              minSizeParam={0}
              isOpenMoreFilter={isOpenMoreFilter}
              setOpenMoreFilter={setIsOpenMoreFilter}
            /> */}
          </div>
          <div className="grid grid-cols-1 gap-8" ref={containerRef}>
            {listings.map((item: Listing, index: number) => (
              <div
                key={item.id}
                onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <StayCardH data={item} isLoaded={loadedCards > index} />
              </div>
            ))}
          </div>
          <div className="flex mt-16 justify-center items-center">{/* <Pagination /> */}</div>
        </div>
{/* 
        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )} */}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {!isLoaded ? (
              <div className="h-[100vh] relative animate-pulse"></div>
            ) : (
              <MapContainer
                center={[listings[0]?.lat || 0, listings[0]?.lng || 0]}
                zoom={12}
                style={{ height: "100vh", position: "relative" }}
                className="animate-appear"
                ref={mapContainerRef} // Assign the ref to the MapContainer
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {listings.length >= 1 &&
                  listings.map((item) => <ListingMarker key={item.id} listing={item} />)}
              </MapContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
