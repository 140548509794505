import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Bookings",
    subHeading:
      "Make direct bookings from the listings, initiating contact with property owners for further details.",
  },
  {
    id: "2",
    heading: "Connect",
    subHeading:
      "Connect directly with property owners, their contact information will be available",
  },
  {
    id: "3",
    heading: "Platforms",
    subHeading:
      "Utilize platforms like booking.com or airbnb.com, if provided, to rent the property for a trial stay",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading desc=" Explore a curated selection of properties across your desired cities. With TryBuy.club, you have the flexibility to:">
        🚀 For Property Buyers
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
