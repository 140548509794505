import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { FaXTwitter, FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa6";

export interface SocialsList1Props {
  className?: string;
}
const socials: SocialType[] = [
  {
    name: "Facebook",
    icon: FaFacebook,
    href: "https://www.facebook.com/share/FwGasRj2T9SJ5jEH/?mibextid=qi2Omg",
  },
  { name: "X", icon: FaXTwitter, href: "https://x.com/TryBuyClub?t=edzh0gVQW4SWKfNs5cbLDQ&s=09" },
  { name: "YouTube", icon: FaYoutube, href: "https://www.youtube.com/@TryBuyClub" },
  { name: "Instagram", icon: FaInstagram, href: "https://www.instagram.com/trybuy.club?igsh=MWZibTEyeHp4dmZyNw==" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
        key={index}
      >
        <item.icon />
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
