import React, { FC, useEffect, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L, { LatLngTuple } from "leaflet";

import "leaflet/dist/leaflet.css";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetina: markerIcon2x,
  shadowUrl: markerShadow,
});

interface MapProps {
  center?: LatLngTuple;
  zoom?: number;
  scrollWheelZoom?: boolean;
}

const MapListing: FC<MapProps> = ({ center, zoom = 18, scrollWheelZoom = true }) => {
  const map = useRef<any>(null); // Ref type should be 'any'
  const [mapCenter, setMapCenter] = useState<LatLngTuple | undefined>(center);

  useEffect(() => {
    if (center) {
      setMapCenter(center);
    }
  }, [center]);

  useEffect(() => {
    if (mapCenter && map.current) {
      // Create a custom icon
      const customIcon = L.icon({
        iconUrl: "/../assets/images/home-marker.png",
        iconSize: [40, 40],
        iconAnchor: [16, 32],
        popupAnchor: [-3, -76],
      });

      // Add marker with custom icon to the map
      L.marker(mapCenter, { icon: customIcon }).addTo(map.current);
    }
  }, [mapCenter]);

  return (
    <MapContainer
      ref={map}
      center={mapCenter || [0, 0]}
      zoom={zoom}
      scrollWheelZoom={scrollWheelZoom}
      className="h-full rounded-lg z-40"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>
  );
};

export default MapListing;
