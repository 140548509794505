import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AdItem } from "./PropertyPostModal";
interface InfoModalProps {
  onClick: () => void;
  text: string;
}
const InformationModal: React.FC<InfoModalProps> = ({ onClick, text }) => {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-neutral-800/70"
      onClick={onClick}
    >
      <div className="bg-white p-8 rounded-lg shadow-md max-w-lg flex justify-center flex-col">
        <p className="text-lg">
          {text}
        </p>
        <ButtonPrimary className="mt-4" onClick={onClick}>
          Okay
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default InformationModal;
