import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface PrivacyPolicyProps {
  className?: string;
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ className = "" }) => {
  return (
    <div
      className={`PrivacyPolicy overflow-hidden relative ${className}`}
      data-nc-id="PrivacyPolicy"
    >
      <Helmet>
        <title>Privacy Policy - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Privacy Policy</h1>

        <div className="max-w-3xl mx-auto">
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">I. Introduction</h2>
          <p className="mb-4">
            At TryBuy.club, we revolutionize the way properties are discovered, experienced, and
            purchased...
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold mb-4">II. Scope of this Policy</h2>
          <p className="mb-4">
            This Privacy Policy describes how TryBuy.club and its affiliates (“we,” “us,” or
            “TryBuy.club”) process...
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold mb-4">III. Information We Collect</h2>
          <p className="mb-4">
            This section will detail the types of personal information we collect from users of the
            TryBuy.club Platform...
          </p>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            I. Information Needed to Use the TryBuy.club Platform
          </h2>
          <p className="mb-4">
            <strong>1.1 Contact, Account, and Profile Information.</strong> Such as your first name,
            last name, phone number, postal address, email address, date of birth, and profile
            photo, some of which will depend on the features you use.
          </p>
          <p className="mb-4">
            <strong>1.2 Identity Information.</strong> Where appropriate, we may ask you for an
            image of your government-issued ID (in accordance with applicable laws) or other
            verification information, and/or a selfie when we verify your ID...
          </p>
          <p className="mb-4">
            <strong>1.3 Payment Information.</strong> Such as payment account or bank account
            information. If you are not an TryBuy.club user, we may receive payment information
            relating to you, such as when an TryBuy.club user provides your payment card to complete
            a booking...
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            II. Information You Choose to Give Us
          </h2>
          <p className="mb-4">
            <strong>2.1 Additional Profile Information.</strong> Such as gender, preferred
            language(s), city, and personal description...
          </p>
          <p className="mb-4">
            <strong>2.2 Information About Others.</strong> Such as a payment instrument or contact
            information belonging to another person...
          </p>
          <p className="mb-4">
            <strong>2.3 Biometric Information.</strong> Such as facial recognition data derived from
            photos and identification documents you submit for verification, where offered and with
            your consent where required by applicable law...
          </p>
          <p className="mb-4">
            <strong>2.4 Other Information.</strong> Such as when you fill in a form, add information
            to your account, respond to surveys, post to community forums, participate in
            promotions, communicate with TryBuy.club Support and other Members, import or manually
            enter address book contacts...
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            III. Information Automatically Collected
          </h2>
          <p className="mb-4">
            <strong>3.1 Geolocation Information.</strong> Such as precise or approximate location
            determined from your IP address, mobile or other device’s GPS...
          </p>
          <p className="mb-4">
            <strong>3.2 Usage Information.</strong> Such as searches for Listings, bookings you have
            made, additional services you have added...
          </p>
          <p className="mb-4">
            <strong>3.3 Device Information.</strong> Such as IP address, hardware and software
            information, device information...
          </p>
          <p className="mb-4">
            <strong>3.4 Cookies and Similar Technologies.</strong> As Described in Our Cookie
            Policy...
          </p>
          <p className="mb-4">
            <strong>3.5 Payment Transaction Information.</strong> Such as payment instrument used,
            date and time, payment amount...
          </p>

          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            IV. Information We Collect from Third Parties
          </h2>
          <p className="mb-4">
            <strong>4.1 Third-Party Applications.</strong> If you choose to link, connect, or login
            to the TryBuy.club Platform with a third-party service...
          </p>
          <p className="mb-4">
            <strong>4.2 Background Information Providers.</strong> For Members in the United States,
            To the extent permitted by applicable laws...
          </p>
          <p className="mb-4">
            <strong>4.3 Complainants.</strong> If a Host, Guest, or any third party submits a
            complaint about you...
          </p>
          <p className="mb-4">
            <strong>4.4 Financial Institutions.</strong> If you elect to pay with funds from your
            bank account...
          </p>
          <p className="mb-4">
            <strong>4.5 Other Sources.</strong> To the extent permitted by applicable law, we may
            receive additional information about you...
          </p>
          {/* Additional sections can be added here */}
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            V. How We Use Information We Collect
          </h2>

          <h3 className="text-lg lg:text-xl font-semibold mb-2">
            1. Provide, Improve, and Develop the TryBuy.club
          </h3>
          <p>We may process this information to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Enable you to access the TryBuy.club Platform and make and receive payments</li>
            <li>Enable you to communicate with others</li>
            <li>Process your request</li>
            <li>Perform analytics, debug, and conduct research</li>
            <li>Develop and improve our products and services</li>
            <li>Provide customer service training</li>
            <li>Send you messages, updates, security alerts, and account notifications</li>
            <li>Process, handle, or assess claims</li>
            <li>
              Determine your country of residence based on automated review of your account
              information and your interactions with the TryBuy.club Platform
            </li>
            <li>
              Personalize and customize your experience based on your interactions with the
              TryBuy.club Platform, your search and booking history, your profile information and
              preferences, and other content you submit, and enable your use of our product
            </li>
          </ul>

          <h3 className="text-lg lg:text-xl font-semibold mb-2">
            2. Safeguard the TryBuy.club Platform and Community
          </h3>
          <p>We may process this information to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Detect, prevent, assess, and address fraud and security risks</li>
            <li>
              Verify or authenticate information provided by you, including identity information
            </li>
            <li>
              Conduct checks against databases and other information sources, including background
              checks
            </li>
            <li>
              Comply with our legal obligations, protect the health and well-being of our Guests,
              Hosts, Hosts’ employees, and members of the public
            </li>
            <li>Resolve disputes with our Members</li>
            <li>Enforce our agreements with third parties</li>
            <li>
              Comply with law, respond to legal requests, prevent harm, and protect our rights
            </li>
            <li>Enforce our Terms</li>
            <li>
              Assess or evaluate your interactions with the TryBuy.club Platform and information
              obtained from third parties
            </li>
          </ul>

          <h3 className="text-lg lg:text-xl font-semibold mb-2">
            3. Provide, Personalize, Measure, and Improve our Advertising and Marketing
          </h3>
          <p>We may process this information to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Send you promotional and marketing messages, and other information</li>
            <li>
              Show, personalize, measure, and improve our advertising on advertising platforms
            </li>
            <li>
              Administer referral programs, rewards, surveys, sweepstakes, contests, or other
              promotional activities or events sponsored or managed by TryBuy.club or its
              third-party partners
            </li>
            <li>
              Analyze characteristics and preferences to send you promotional messages, marketing,
              advertising, and other information that we think might be of interest to you
            </li>
            <li>Invite you to events and relevant opportunities</li>
          </ul>

          <h3 className="text-lg lg:text-xl font-semibold mb-2">
            4. Analyze and Share Your Communications
          </h3>
          <p>
            We may review, scan, or analyze your communications on the TryBuy.club Platform for
            reasons outlined in the “How We Use Information We Collect” section of this policy,
            including fraud prevention, security investigations, risk assessment, regulatory
            compliance, product development, research, analytics, enforcing our Terms of Service,
            and customer support purposes.
          </p>
          <p>
            For example, as part of our fraud prevention efforts, we scan and analyze messages to
            mask contact information and references to other sites and, subject to applicable law,
            we scan and analyze all images uploaded by users to the TryBuy.club platform in message
            threads, profiles, listings, and experiences for certain illegal or inappropriate
            activities – such as evidence of child exploitation – for the purpose of identifying and
            reporting content violations to appropriate authorities. In some cases, we may also
            scan, review, or analyze messages to debug, improve, and expand product offerings. We
            use automated methods where reasonably possible. Occasionally we may need to manually
            review communications, such as for fraud investigations and customer support, or to
            assess and improve the functionality of these automated tools. We will not review, scan,
            or analyze your messaging communications to send third-party marketing messages to you
            and we will not sell reviews or analyses of these communications.
          </p>

          <h3 className="text-lg lg:text-xl font-semibold mb-2">5. Provide Payment Services</h3>
          <p>
            Personal information is used to enable, or authorize third parties to use, Payment
            Services, such as to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>
              Detect and prevent money laundering, fraud, abuse, and security incidents, as well as
              conduct risk assessments
            </li>
            <li>
              Comply with legal and compliance obligations, such as anti-money laundering
              regulations and sanctions enforcement
            </li>
            <li>Enforce the Payment Terms and other payment policies</li>
            <li>Provide and improve Payment Services</li>
          </ul>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Sharing and Disclosure
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>SHARING AND DISCLOSURE.</strong>
            </p>

            <p>
              <strong>1 Sharing With Your Consent or at Your Direction.</strong> Where you provide
              consent or direct us to share your information, we share your information as described
              at the time of consent or choice, such as when authorizing a third-party application
              or website to access your TryBuy.club account, processing a claim, applying for
              flexible payment and financing products, or participating in promotional activities by
              TryBuy.club partners or third parties.
            </p>

            <p>
              <strong>2 Who We Share With.</strong> We may share your information with:
            </p>

            <ul>
              <li>
                <strong>2.1 Other Members.</strong> To help facilitate bookings or other
                interactions between Members (who may be located in, or use service providers
                located in, jurisdictions with varying levels of data protection), we may share
                information in certain situations, as described in Sharing and disclosing
                information with other Members.
              </li>
              <li>
                <strong>2.2 Members’ Service Providers.</strong> Hosts may use third-party services
                to help manage or deliver their services, such as cleaning services or lock
                providers. Hosts may use features on the TryBuy.club Platform to share information
                about the Guest with such third-party service providers. Other Members may use
                services other than TryBuy.club to process your data, including email or reservation
                management software. Such services are outside of TryBuy.club’s control and will be
                subject to applicable law.
              </li>
              <li>
                <strong>2.3 Building Management.</strong> We may share personal information of Hosts
                and Guests with the owner(s) of an Accommodation, a homeowners’ association, and/or
                their agent, such as a building operator or property management company (“Building
                Management”), including booking information and information related to compliance
                with applicable laws, in order to facilitate programs with Building Management.
                Guest booking and personal information, including guest contact information, may be
                shared with the Building Management of the building, complex, or community where a
                host lives and/or the listing is located to facilitate hosting services, compliance
                with applicable laws, security, billing, and other services.
              </li>
              <li>
                <strong>2.4 TryBuy.club for Work Partners.</strong> If a booking is designated as
                being for a business or work purpose and is made by a Guest affiliated with an
                Enterprise enrolled in TryBuy.club for Work, we may disclose information related to
                the booking to the Enterprise to the extent necessary for the adequate performance
                of TryBuy.club’s contract with the Enterprise and to provide the services. At the
                request of the Enterprise or the Guest, we may also share this information with
                third parties engaged by the Enterprise to provide support services.
              </li>
              <li>
                <strong>2.5 Service Providers.</strong> We share personal information with
                affiliated and unaffiliated service providers (including their service providers) to
                help us run our business and for their compliance purposes, including those that
                help us: verify your identity or authenticate your identification documents; check
                information against public databases; conduct background checks, fraud prevention,
                security investigations, and risk assessments; perform product development,
                maintenance, and debugging; allow the provision of the TryBuy.club Services through
                third-party platforms and software tools; provide customer service, advertising, or
                payments services; offer additional services you select; process, handle, or assess
                claims; review, scan, and analyze communications on the TryBuy.club Platform for
                certain purposes, such as evidence of child exploitation.
              </li>
              <li>
                <strong>2.6 Corporate Affiliates.</strong> To support us in providing, integrating,
                promoting and improving the TryBuy.club Platform, Payment Services, and our
                affiliates’ services, we may share personal information within our corporate family
                of companies.
              </li>
              <li>
                <strong>2.7 TryBuy.club Payments.</strong> In order to facilitate payments on or
                through the Platform, certain information will be shared with the relevant Payments
                entity.
              </li>
            </ul>

            <p>
              <strong>3 Why We May Share Your Information.</strong> We may share your information in
              order to:
            </p>

            <ul>
              <li>
                <strong>3.1 Build Your Public Profile.</strong> Information you share publicly on
                the TryBuy.club Platform may be indexed through third-party search engines. We may
                make certain information publicly visible to others.
              </li>
              <li>
                <strong>
                  3.2 Comply with Law, Respond to Legal Requests, Prevent Harm, and Protect Our
                  Rights.
                </strong>
              </li>
              <li>
                <strong>3.2.1 Disclosure.</strong> We may disclose your information to courts, law
                enforcement, governmental or public authorities, tax authorities, authorized third
                parties, or other Members, if and to the extent we are required or permitted to do
                so by law.
              </li>
              <li>
                <strong>3.2.2 To Tax Authorities.</strong> Where legally required or permissible
                according to applicable law, we may disclose Hosts’ and/or Guests’ information to
                relevant tax authorities or other governmental agencies, depending on where you are
                based, for the purpose of the tax authorities’ determination of proper compliance
                with relevant tax obligations.
              </li>
              <li>
                <strong>
                  3.2.3 To Governmental Authorities for Registrations, Notifications, Permits, or
                  License Applications or Numbers.
                </strong>{" "}
                In jurisdictions where TryBuy.club facilitates or requires a registration,
                notification, permit, or license application or number of a Host with a governmental
                authority, we may share information of participating Hosts with the relevant
                authority, during the application process, when the Listing is published, and
                periodically thereafter.
              </li>
              <li>
                <strong>3.2.4 Notification.</strong> Where appropriate and/or legally required, we
                may notify Members about legal requests.
              </li>
              <li>
                <strong>3.3 Effectuate Business Transfers.</strong> If TryBuy.club undertakes or is
                involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or
                insolvency event, then we may sell, transfer, or share some or all of our assets,
                including your information in connection with such transaction or in contemplation
                of such transaction, such as due diligence.
              </li>
            </ul>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Third-Party Partners and Integrations
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>THIRD-PARTY PARTNERS AND INTEGRATIONS.</strong>
            </p>

            <p>
              <strong>1 Linking Third-Party Services.</strong> You can link your TryBuy.club account
              with certain third-party services, such as social networks. When you direct the data
              sharing by creating this link:
            </p>

            <ul>
              <li>
                Some of the information provided to us from linking accounts may be published on
                your public profile.
              </li>
              <li>
                Information you provide to us from the linking of your accounts may be stored,
                processed, and transmitted for fraud prevention, security investigations, and risk
                assessment purposes.
              </li>
              <li>
                We share information about your reservation with third-party travel partners and
                reward programs.
              </li>
              <li>
                Publication and display of information that you provide to the TryBuy.club Platform
                through this linkage is subject to your settings and authorizations on the
                TryBuy.club Platform and the third-party service.
              </li>
            </ul>

            <p>
              <strong>2 Third-Party Service Terms.</strong> Parts of TryBuy.club may link to
              third-party services. TryBuy.club does not own or control these third parties. When
              you interact with these third parties and choose to use their service, you are
              providing your information to them. Your use of these services is subject to the
              privacy policies of those providers, including Google Maps/Earth Additional Terms of
              Use, Google Privacy Policy, Paypal Privacy Statement, Stripe Privacy Policy.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Your Rights</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>YOUR RIGHTS.</strong>
            </p>

            <p>
              You can exercise any of the rights described in this section consistent with
              applicable law. See here for information on data subject rights requests and how to
              submit a request. We may ask you to verify your identity and request before taking
              further action on your request.
            </p>

            <p>
              <strong>1 Managing Your Information.</strong> You can access and update some of your
              personal information through your Account settings. You are responsible for keeping
              your personal information up to date.
            </p>

            <p>
              <strong>2 Access and Portability.</strong> In some jurisdictions, applicable law may
              entitle you to request certain copies of your personal information or information
              about how we handle your personal information, request copies of personal information
              that you have provided to us in a structured, commonly used, and machine-readable
              format, and/or request that we transmit this information to another service provider,
              where technically feasible.
            </p>

            <p>
              <strong>3 Erasure.</strong> In some jurisdictions, you can request that your personal
              information be deleted. Please note that if you request deletion of your personal
              information, or if your account is suspended, terminated, or voluntarily closed:
            </p>

            <ul>
              <li>
                <strong>3.1 Legitimate Business Interests.</strong> We may retain your personal
                information as necessary for our legitimate business interests, such as prevention
                of money laundering, fraud detection and prevention, and enhancing safety. For
                example, if we suspend an TryBuy.club Account for fraud or safety reasons, we may
                retain information from that TryBuy.club Account to prevent that Member from opening
                a new TryBuy.club Account in the future.
              </li>
              <li>
                <strong>3.2 Legal, Tax, Reporting, and Auditing Obligations.</strong> We may retain
                and use your personal information to the extent necessary to comply with our legal,
                tax, reporting, and auditing obligations.
              </li>
              <li>
                <strong>3.3 Shared Information.</strong> Information you have shared with others,
                such as Reviews and forum postings, may continue to be publicly visible on
                TryBuy.club, even after your TryBuy.club Account is canceled.
              </li>
              <li>
                <strong>3.4 Residual Copies.</strong> Because we take measures to protect data from
                accidental or malicious loss and destruction, residual copies of your personal
                information may not be removed from our backup systems for a limited period of time.
              </li>
            </ul>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            TERM AND LOCATION OF PERSONAL DATA STORAGE
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>YOUR RIGHTS.</strong>
            </p>

            <p>
              You can exercise any of the rights described in this section consistent with
              applicable law. See here for information on data subject rights requests and how to
              submit a request. We may ask you to verify your identity and request before taking
              further action on your request.
            </p>

            <p>
              <strong>1 Managing Your Information.</strong> You can access and update some of your
              personal information through your Account settings. You are responsible for keeping
              your personal information up to date.
            </p>

            <p>
              <strong>2 Access and Portability.</strong> In some jurisdictions, applicable law may
              entitle you to request certain copies of your personal information or information
              about how we handle your personal information, request copies of personal information
              that you have provided to us in a structured, commonly used, and machine-readable
              format, and/or request that we transmit this information to another service provider,
              where technically feasible.
            </p>

            <p>
              <strong>3 Erasure.</strong> In some jurisdictions, you can request that your personal
              information be deleted. Please note that if you request deletion of your personal
              information, or if your account is suspended, terminated, or voluntarily closed:
            </p>

            <ul>
              <li>
                <strong>3.1 Legitimate Business Interests.</strong> We may retain your personal
                information as necessary for our legitimate business interests, such as prevention
                of money laundering, fraud detection and prevention, and enhancing safety. For
                example, if we suspend an TryBuy.club Account for fraud or safety reasons, we may
                retain information from that TryBuy.club Account to prevent that Member from opening
                a new TryBuy.club Account in the future.
              </li>
              <li>
                <strong>3.2 Legal, Tax, Reporting, and Auditing Obligations.</strong> We may retain
                and use your personal information to the extent necessary to comply with our legal,
                tax, reporting, and auditing obligations.
              </li>
              <li>
                <strong>3.3 Shared Information.</strong> Information you have shared with others,
                such as Reviews and forum postings, may continue to be publicly visible on
                TryBuy.club, even after your TryBuy.club Account is canceled.
              </li>
              <li>
                <strong>3.4 Residual Copies.</strong> Because we take measures to protect data from
                accidental or malicious loss and destruction, residual copies of your personal
                information may not be removed from our backup systems for a limited period of time.
              </li>
            </ul>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">User Rights</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>USER RIGHTS</strong>
            </p>

            <p>
              The user has the following rights regarding the processing of their personal data by
              the Administrator:
            </p>

            <p>
              <strong>1. Right to access personal data.</strong> The user has the right to obtain
              confirmation from the Administrator whether their personal data are being processed
              and, if so, to access this data, including obtaining a copy thereof. A registered user
              of the trybuy.club internet platform can access their personal data processed by the
              Administrator in their profile, under the Personal Data section.
            </p>

            <p>
              <strong>1.2. Right to rectification.</strong> The user has the right to request the
              Administrator to rectify without undue delay inaccurate personal data concerning them.
              A registered user of the trybuy.club internet platform can also correct their data
              using the functionalities within the platform.
            </p>

            <p>
              <strong>1.3. Right to erasure (right to be forgotten).</strong> The user has the right
              to request from the Administrator the erasure of all or part of their personal data,
              where one of the following grounds applies: the personal data are no longer necessary
              in relation to the purposes for which they were collected or otherwise processed;
              consent on which the processing is based has been withdrawn and there is no other
              legal ground for the processing; the personal data have been unlawfully processed; the
              personal data must be erased for compliance with a legal obligation in Union or Member
              State law; the personal data have been collected in relation to the offer of
              information society services. A registered user of the trybuy.club internet platform
              can delete their personal data within the platform by deleting their advertisement
              entirely or parts of the data within the advertisement, using the relevant tools. The
              same user has the option to delete their registration on the internet platform from
              the Profile menu, sub-menu Delete Profile, by entering their user password.
            </p>

            <p>
              The Administrator deletes all personal data, except for personal messages to other
              users, as well as other information that the Administrator is required to keep by law
              or for its legitimate interests.
            </p>

            <p>
              <strong>1.4. Right to restriction of processing.</strong> The user has the right to
              obtain from the Administrator restriction of processing where: the accuracy of the
              personal data is contested by the user, for a period enabling the Administrator to
              verify the accuracy of the personal data; the processing is unlawful and the user
              opposes the erasure of the personal data and requests the restriction of their use
              instead; the Administrator no longer needs the personal data for the purposes of the
              processing, but they are required by the user for the establishment, exercise, or
              defense of legal claims; the user has objected to processing pending the verification
              whether the legitimate grounds of the Administrator override those of the user.
            </p>

            <p>
              <strong>1.5. Right to data portability.</strong> The user has the right to receive
              their personal data in a structured, commonly used, and machine-readable format from
              the Administrator. This right applies only to information processed automatically, for
              which the user initially gave their consent or which is processed based on a contract.
            </p>

            <p>
              <strong>
                1.6. The user has the right not to be subject to a decision based solely on
                automated processing, including profiling, which produces legal effects concerning
                them or similarly significantly affects them, unless there are grounds for such
                processing and appropriate safeguards are provided for the user's rights, freedoms,
                and legitimate interests.
              </strong>
            </p>

            <p>
              <strong>1.7. Right to withdraw consent.</strong> If the user does not want all or part
              of their personal data to continue to be processed by the Administrator for specific
              or all processing purposes, they may withdraw their consent at any time. By
              withdrawing consent for the processing of personal data necessary for the creation and
              maintenance of a profile on the internet platform, the user account will be deleted.
              Such withdrawal of consent does not affect the lawfulness of processing based on
              consent before its withdrawal.
            </p>

            <p>
              <strong>1.8. Right to object.</strong> The user has the right to object, on grounds
              relating to their particular situation, at any time to processing of personal data
              concerning them, including profiling based on these provisions. The user may object at
              any time to the processing of their personal data for direct marketing purposes. There
              are certain informational messages about the Services, receipt of which the user
              cannot opt out, such as notifications of changes to the Services, Terms of Service,
              Privacy Policy, and similar messages.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">User Rights</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>EXERCISING RIGHTS</strong>
            </p>

            <p>
              If the user wishes to exercise any of the above rights, they may do so by submitting a
              written request to the Administrator, personally or through an authorized
              representative, with an expressly notarized power of attorney sent to the email
              address provided below. In such case, the request must be notarized. In their request,
              the user should include a valid email address, name, address, and telephone number for
              correspondence. When submitting the request to exercise rights, the Administrator may
              require identification of the user by providing an identity document, electronic
              signature, or other methods and means of identification.
            </p>

            <p>
              The Administrator will fulfill the request in accordance with the requirements of
              applicable legislation as soon as possible, without undue delay, within one month of
              receiving the request. If necessary, this period may be extended by an additional two
              months, taking into account the complexity and number of requests, for which the user
              will be notified.
            </p>

            <p>
              In certain cases specified by applicable legislation, the Administrator may entirely
              or partially refuse to fulfill a user's request, for which the user will be informed.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Security</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>SECURITY.</strong>
            </p>

            <p>
              While no organization can guarantee perfect security, we are continuously implementing
              and updating administrative, technical, and physical security measures to help protect
              your information against unlawful or unauthorized access, loss, destruction, or
              alteration.
            </p>

            <p>
              The Administrator takes the necessary technical and organizational measures to
              safeguard the information it possesses, including personal data, from loss, theft,
              disclosure, modification, and against any other unlawful forms of processing.
            </p>

            <p>
              These measures are subject to regular review and continuous adaptation according to
              the latest technical standards. In the event that the protection of personal data is
              breached and as a result there is a high risk to the rights and freedoms of Users, the
              Administrator will take necessary actions to promptly notify the data subjects as far
              as possible within 72 hours.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Privacy Policy Changes
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>CHANGES TO THIS PRIVACY POLICY.</strong>
            </p>

            <p>
              We reserve the right to modify this Privacy Policy at any time in accordance with
              applicable law. If we do so, we will post the revised Privacy Policy and publish the
              “Last Updated” date at the top. They enter into force on the day of their publication.
              The user is obliged to check the validity of the Terms at any time before using the
              Internet platform. If the User continues to use the Administrator's services after the
              update of the Terms and the Privacy Policy, it will be considered that he has read,
              accepted and agreed to the changes.
            </p>

            <p>
              If you disagree with the revised Privacy Policy, you can cancel your Account. If you
              do not cancel your Account, your continued access to or use of the TryBuy.club
              Platform will be subject to the revised Privacy Policy.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Contact Information and Responsible
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>TryBuy.club.</strong> For questions or complaints about this Privacy Policy or
              TryBuy.club’s handling of personal information email us at .............. ; for
              payments related matter please use the contact information provided in the Contact
              Information page.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Definitions - Privacy Policy
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              <strong>Undefined terms</strong> in this Privacy Policy have the same definition as in
              our Terms of Service (“Terms”).
            </p>
            <p>
              <strong>Cookies Policy</strong>
            </p>
            <p>
              TryBuy.club uses cookies, mobile identifiers, tracking URLs, log data, and similar
              technologies to help provide, protect, and improve the TryBuy.club Platform. This
              Cookie Policy (“Policy”) supplements the TryBuy.club Privacy Policy and explains how
              and why we use these technologies and the choices you have.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Why TryBuy.club Uses These Technologies
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>We use these technologies for a number of purposes, such as:</p>
            <ul className="list-disc pl-6">
              <li>
                - to enable you to use and access the TryBuy.club Platform and the Payment Services
              </li>
              <li>
                - to enable, facilitate, and streamline the functioning of and your access to the
                TryBuy.club Platform
              </li>
              <li>
                - to better understand how you navigate through and interact with the TryBuy.club
                Platform and to improve the TryBuy.club Platform
              </li>
              <li>
                - to serve you tailored advertising (such as on the TryBuy.club Platform, emails,
                and on third-party websites)
              </li>
              <li>- to show you content (e.g., advertisements) that is more relevant to you</li>
              <li>
                - to monitor and analyze the performance, operation, and effectiveness of the
                TryBuy.club Platform and TryBuy.club advertisements
              </li>
              <li>- to enforce legal agreements that govern the use of the TryBuy.club Platform</li>
              <li>- for fraud detection and prevention, trust and safety, and investigations</li>
              <li>
                - for purposes of our own customer support, analytics, research, product
                development, and regulatory compliance
              </li>
            </ul>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Cookies</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              When you visit the TryBuy.club Platform we may place cookies on your device. Cookies
              are small text files that websites send to your computer or other Internet-connected
              device to uniquely identify your browser or to store information or settings in your
              browser. Cookies allow us to recognize you when you return. They also help us provide
              a customized experience and can enable us to detect certain kinds of fraud. In many
              cases, the information we collect using cookies and other tools is only used in a
              non-identifiable manner without reference to personal information. For example, we may
              use information we collect to better understand website traffic patterns and to
              optimize our website experience. In some cases, we associate the information we
              collect using cookies and other technology with your personal information. Our
              business partners may also use these tracking technologies on the TryBuy.club Platform
              or engage others to track your behavior on our behalf.
            </p>
            <p>
              There are two types of cookies used on the TryBuy.club Platform: “session cookies” and
              “persistent cookies.” Session cookies normally expire when you close your browser,
              while persistent cookies remain on your device after you close your browser and can be
              used again the next time you access the TryBuy.club Platform.
            </p>
            <p>
              In many cases, you can manage cookie preferences and opt-out of having cookies and
              other data collection technologies used by adjusting the settings on your browser. All
              browsers are different, so visit the “help” section of your browser to learn about
              cookie preferences and other privacy settings that may be available. Please note that
              if you choose to remove or reject cookies or clear local storage this could affect the
              features, availability, and functionality of the TryBuy.club Platform.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Flash Cookies</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              We may use Flash Cookies, also known as Local Stored Objects, and similar technologies
              to personalize and enhance your online experience. A Flash cookie is a small data file
              placed on a computer using Adobe Flash technology. The Adobe Flash Player is an
              application that allows rapid development of dynamic content, such as video clips and
              animation.
            </p>
            <p>
              We use Flash cookies to personalize and enhance your online experience and to deliver
              content for Flash players. We may also use Flash cookies for security purposes, to
              gather certain website metrics and to help remember settings and preferences. Flash
              cookies are managed through a different interface than the one provided by your web
              browser. To manage Flash cookies, please visit Adobe’s website.
            </p>
            <p>
              If you disable Flash cookies or other similar technologies, please note that you may
              not have access to certain content and product features such as your device
              remembering a Listing that you viewed during a previous visit.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">
            Device Information
          </h1>

          <div className="max-w-3xl mx-auto">
            <p>
              We may use device-related information to authenticate users. For example, we may use
              your IP address, browser information, or other data provided by your browser or device
              to identify the device being used to access our platform. We may also use these
              device-related techniques for associating you with different devices that you may use
              to access our content including for fraud-protection purposes and to better target
              advertising.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Third Parties</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              TryBuy.club permits third parties to collect the information described above through
              our Service and discloses such information to third parties for business purposes as
              described in this Privacy Policy, including but not limited to providing advertising
              on our Service and elsewhere based on users’ online activities over time and across
              different sites, services, devices.
            </p>

            <p>
              Third parties, including Facebook, place technologies such as pixels and SDKs on the
              TryBuy.club Platform. These technologies (1) help us analyze how you use the
              TryBuy.club Platform, such as by noting the third-party services from which you
              arrived, (2) market and advertise TryBuy.club services to you on the TryBuy.club
              Platform and third-party websites, (3) help us detect or prevent fraud or conduct risk
              assessments, and (4) collect information about your activities on the TryBuy.club
              Platform, other sites, and/or the ads you have clicked on.
            </p>

            <p>
              For more information on how we share information with partners, see the TryBuy.club
              Privacy Policy.
            </p>
          </div>
          <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Your Choices</h1>

          <div className="max-w-3xl mx-auto">
            <p>
              Most browsers automatically accept cookies, but you can modify your browser setting to
              decline cookies by visiting the Help portion of your browser’s toolbar. While you may
              disable cookies through your browser settings, the TryBuy.club Platform currently does
              not respond to a “Do Not Track” signal in the HTTP header from your browser or mobile
              application due to lack of standardization regarding how that signal should be
              interpreted.
            </p>

            <p>
              Flash cookies operate differently than browser cookies, and cookie management tools
              available in a web browser will not remove flash cookies. To learn more about how to
              manage flash cookies, you can visit the Adobe website and make changes at the Global
              Privacy Settings Panel.
            </p>

            <p>
              Your mobile device may allow you to control cookies through its settings function.
              Refer to your device manufacturer’s instructions for more information.
            </p>

            <p>
              If you choose to decline cookies, some parts of the TryBuy.club Platform may not work
              as intended or may not work at all.
            </p>
          </div>
        </div>

        {/* Background section for aesthetic */}
        <div className="relative py-16">
          <BackgroundSection />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
