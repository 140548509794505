import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Modal from "./Modal";
import usePropertyListModal from "../../hooks/usePropertyListModal";
import Heading from "../../components/Heading";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import CategoryInput from "../../components/Inputs/CategoryInput";
import CountrySelect from "../../components/Inputs/CountrySelect";
import Map from "../../components/Map";
import Counter from "../../components/Inputs/Counter";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosResize } from "react-icons/io";
import { CiLink } from "react-icons/ci";
import toast from "react-hot-toast";
import Input from "../../components/Inputs/Input";
import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import TextArea from "../../components/Inputs/TextArea";
import CheckBox from "../../components/Inputs/CheckBox";
import { FaChalkboardTeacher, FaHome } from "react-icons/fa";
import {
  MdAccessible,
  MdApartment,
  MdContactPhone,
  MdElevator,
  MdFitnessCenter,
  MdLocalLaundryService,
  MdLocalParking,
  MdNaturePeople,
  MdPeople,
  MdPets,
  MdPool,
  MdSmartphone,
  MdStorage,
  MdTv,
  MdVideocam,
  MdVolumeUp,
  MdWifi,
} from "react-icons/md";
import CitySelect from "components/Inputs/CitySelect";
import AddressInput from "components/Inputs/AddressInput";
import { LatLngTuple } from "leaflet";
import useLoginModal from "hooks/useLoginModal";
import { UserContext } from "UserContext";
import AmenitiesInput from "components/Inputs/AmenitiesInput";
import useCountries from "hooks/useCountries";
import { FaAirbnb, FaCircleInfo } from "react-icons/fa6";
import { TbBrandBooking } from "react-icons/tb";
import CurrencyDropdown from "components/Header/CurrencyDropdown";
import UnitDropdown from "components/Header/SizeUnitDropdown";
import ModalButton from "components/ModalButton";
import useInfoModal from "hooks/useInfoModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import InfoModal from "./InformationModal";
import PackageInformationModal from "./PackageInformationModal";
import { TwMainColor } from "data/types";
import { City, Loc } from "Types/Listing";
import { FormData as FormD } from "Types/Listing";
export const categories = [
  {
    label: "Apartment",
    slug: "apartment",
    icon: MdApartment,
    description: "Type: Apartment",
  },
  {
    label: "House",
    slug: "house",
    icon: FaHome,
    description: "Type: House",
  },
  {
    label: "Other",
    slug: "other",
    icon: FaChalkboardTeacher,
    description: "Type: Other",
  },
];
interface Amenity {
  id: number;
  label: string;
  slug: string;
  icon: string;
  description: string;
}
interface OfferPrice {
  id: number;
  days: number;
  price: number;
}
export interface AdItem {
  id: string;
  name: string;
  data: OfferPrice[];
  bonusDaysFirstListing: number;
  perks: string[];
  badgeColor?: TwMainColor;
}
export const adItems: AdItem[] = [
  {
    id: "free",
    name: "Free (No advertisment)",
    bonusDaysFirstListing: 0,
    badgeColor: "green",
    data: [
      {
        id: 1,
        days: 0,
        price: 0.0,
      },
    ],
    perks: [""],
  },
  {
    id: "core",
    name: "Core",
    bonusDaysFirstListing: 5,
    badgeColor: "red",
    data: [
      {
        id: 1,
        days: 2,
        price: 1.2,
      },
      {
        id: 2,
        days: 5,
        price: 2.4,
      },
      {
        id: 3,
        days: 12,
        price: 4.9,
      },
      {
        id: 4,
        days: 28,
        price: 8.9,
      },
    ],
    perks: [
      "Displaying the listing before the free ones (after Core and Ultra listings).",
      "More visits from free listings and better indexing by search engines",
    ],
  },
  {
    id: "plus",
    name: "Plus",
    badgeColor: "indigo",
    bonusDaysFirstListing: 2,
    data: [
      {
        id: 1,
        days: 1,
        price: 3.5,
      },
      {
        id: 2,
        days: 2,
        price: 4.9,
      },
      {
        id: 3,
        days: 5,
        price: 11.9,
      },
      {
        id: 4,
        days: 12,
        price: 19.9,
      },
      {
        id: 5,
        days: 28,
        price: 35,
      },
    ],
    perks: [
      "Displaying the listing before the Free and Core ones (after Ultra listings).",
      "Much more visits from 'Core' and Free listings and better indexing by search engines",
    ],
  },
  {
    id: "ultra",
    name: "Ultra",
    badgeColor: "yellow",
    bonusDaysFirstListing: 2,
    data: [
      {
        id: 1,
        days: 1,
        price: 7.0,
      },
      {
        id: 2,
        days: 2,
        price: 9.8,
      },
      {
        id: 3,
        days: 5,
        price: 23.8,
      },
      {
        id: 4,
        days: 12,
        price: 39.8,
      },
      {
        id: 5,
        days: 28,
        price: 70,
      },
    ],
    perks: [
      "Displaying the listing before the Free, Core and Plus ones.",
      "The most visited ads on the site with the best indexation by search engines.",
    ],
  },
];

export const amenities = [
  {
    id: 1,
    label: "On-site Parking",
    slug: "on-site-parking",
    icon: MdLocalParking,
    description: "Designated parking spaces available within the apartment complex premises.",
  },
  {
    id: 2,
    label: "Elevator",
    slug: "elevator",
    icon: MdElevator,
    description:
      "An elevator is available for easy access to different floors of the apartment building, particularly useful in multi-story buildings.",
  },
  {
    id: 3,
    label: "Security Cameras",
    slug: "security-cameras",
    icon: MdVideocam,
    description:
      "Surveillance cameras installed in common areas for added security and monitoring.",
  },
  {
    id: 4,
    label: "Intercom/Doorbell",
    slug: "intercom-doorbell",
    icon: MdVolumeUp,
    description:
      "An intercom or doorbell system for communication and allowing entry to the apartment.",
  },
  {
    id: 5,
    label: "High-Speed Internet Connection",
    slug: "high-speed-internet",
    icon: MdWifi,
    description: "A fast and reliable internet connection for residents' use.",
  },
  {
    id: 6,
    label: "Cable or Satellite TV Connection",
    slug: "tv-connection",
    icon: MdTv,
    description: "Access to cable or satellite television services for entertainment.",
  },
  {
    id: 7,
    label: "Washer and Dryer",
    slug: "washer-dryer",
    icon: MdLocalLaundryService,
    description: "In-unit or shared facilities for washing and drying clothes.",
  },
  {
    id: 8,
    label: "Storage Spaces",
    slug: "storage-spaces",
    icon: MdStorage,
    description: "Additional storage areas such as closets, cabinets, or basement/attic storage.",
  },
  {
    id: 9,
    label: "Gym or Fitness Center",
    slug: "gym-fitness-center",
    icon: MdFitnessCenter,
    description: "An on-site gym or fitness facility for residents' use.",
  },
  {
    id: 10,
    label: "Swimming Pool",
    slug: "swimming-pool",
    icon: MdPool,
    description: "An outdoor or indoor swimming pool available for residents' use.",
  },
  {
    id: 11,
    label: "Lounge or Community Room",
    slug: "lounge-community-room",
    icon: MdPeople,
    description: "A shared space for residents to relax, socialize, or host gatherings.",
  },
  {
    id: 12,
    label: "Outdoor Spaces (Garden or Patio)",
    slug: "outdoor-spaces",
    icon: MdNaturePeople,
    description: "Garden areas, patios, or outdoor seating spaces for residents' enjoyment.",
  },
  {
    id: 13,
    label: "Wheelchair Ramps or Accessible Entrances",
    slug: "wheelchair-accessibility",
    icon: MdAccessible,
    description:
      "Features ensuring accessibility for residents with mobility challenges, such as ramps and accessible entrances.",
  },
  {
    id: 14,
    label: "Property Management Contact Information",
    slug: "property-management",
    icon: MdContactPhone,
    description:
      "Contact information for property management for residents' convenience in reporting issues or seeking assistance.",
  },
  {
    id: 15,
    label: "Pet-Friendly Policy",
    slug: "pet-friendly-policy",
    icon: MdPets,
    description:
      "A policy allowing residents to have pets, along with any associated amenities such as a dog park or grooming station.",
  },
  {
    id: 16,
    label: "Smart Home Features",
    slug: "smart-home-features",
    icon: MdSmartphone,
    description:
      "Technological features such as smart thermostats or locks that enhance convenience and efficiency.",
  },
];

const STEPS = {
  CATEGORY: 0,
  AMENITIES: 1,
  LOCATION: 2,
  INFO: 3,
  IMAGES: 4,
  LINKS: 5,
  DESCRIPTION: 6,
  PRICE: 7,
  ADS: 8,
};

interface PropertyPostModalProps {}

export type PropertyPostState = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};
const PropertyPostModal: React.FC<PropertyPostModalProps> = () => {
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "USD");
  const [unit, setUnit] = useState(localStorage.getItem("unit") || "sq ft.");
  const [step, setStep] = useState<number>(STEPS.CATEGORY);
  const [selectedLocation, setSelectedLocation] = useState<Loc>({ latlang: [1, 2] });
  const [selectedCity, setSelectedCity] = useState<City>({ name: "Select a city", coords: [1, 1] });
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentlySelectedPackage, setCurrentlySelectedPackage] = useState<string>(
    "Currently selected: Package Free"
  );
  const router = useNavigate();
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [advertismentInfoOpen, setAdvertismentInfoOpen] = useState<boolean>(false);
  const [offerInfoModalOpen, setOfferInfoModalOpen] = useState<boolean>(false);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<{ [key: string]: number }>({});

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const selectedOfferId = parseInt(e.target.value);
    setSelectedOffer((prevState) => ({
      [itemId]: selectedOfferId,
    }));
  };

  const handlePurchase = (itemId: string, offerId: number) => {
    setCustomValue("packageId", itemId);
    setCustomValue("offerId", offerId);
  };
  const handleClick = (value: string) => {
    setActiveItem(activeItem === value ? null : value);
  };
  const toggleAmenity = (label: string, slug: string) => {
    const index = selectedAmenities.indexOf(label);
    if (index === -1) {
      setSelectedAmenities([...selectedAmenities, label]);
    } else {
      setSelectedAmenities(selectedAmenities.filter((cat) => cat !== label));
    }
  };
  const handleCurrencyChange = (curr: string) => {
    setCurrency(curr);
  };
  const handleUnitChange = (un: string) => {
    setUnit(un);
  };
  useEffect(() => {
    setCurrency(localStorage.getItem("currency") || "USD");
    setUnit(localStorage.getItem("unit") || "sq ft.");
  }, []);
  const propertyPost = usePropertyListModal() as PropertyPostState;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormD>({
    defaultValues: {
      title: "",
      category: "",
      categorySlug: "",
      location: null,
      city: null,
      address: "",
      roomCount: 1,
      bathroomCount: 1,
      guestCount: 1,
      bedCount: 1,
      images: [],
      price: 1,
      propertyPrice: 1,
      priceNegotiable: false,
      description: "",
      size: "",
      locationCoords: [1, 1],
      amenities: [],
      airbnb: "",
      booking: "",
      other: "",
      priceUnit: "",
      sizeUnit: "",
    },
  });
  const setCustomValue = (id: any, value: any) => {
    setValue(id, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const images = watch("images");

  const ImageUpload: React.FC = () => {
    const onDrop = (acceptedFiles: File[]) => {
      if (images.length + acceptedFiles.length > 6) {
        toast.error("You can only upload up to 6 images!");
        return;
      }
      setCustomValue("images", [...images, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/*": [".png", ".jpg", ".jpeg"],
      },
      multiple: true,
      onDrop,
    });

    const handleRemoveImage = (index: number) => {
      const updatedImages = images.filter((_: any, i: number) => i !== index);
      setCustomValue("images", updatedImages);
    };

    return (
      <div className="flex flex-row items-center gap-3 justify-center">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-6" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="bg-gray-100 border border-gray-300 p-6 rounded cursor-pointer flex items-center justify-center flex-col">
              <p className="text-gray-700 font-bold mb-2">
                Place your photos here by dragging them.
              </p>
              <p className="text-gray-500">or</p>
              <label
                htmlFor="imageInput"
                className="cursor-pointer bg-primary hover:opacity-80 text-gray-700 font-bold py-2 px-4 rounded inline-block mt-2"
              >
                click to upload them
              </label>
              <p className="text-sky-700 text-xl font-bold text-center">
                Photos will be displayed in the order you upload them in.
              </p>
            </div>
          </div>
          {images.length > 0 && (
            <div className="flex flex-wrap justify-center relative overflow-y-auto max-h-40">
              {images.map((image: Blob, index: number) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(image) || ""}
                    alt={`uploaded ${index + 1}`}
                    className="w-32 h-32 object-cover m-2 rounded"
                  />
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className="absolute top-0 right-0 text-red-700"
                  >
                    <AiOutlineClose size={30} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    setCustomValue("sizeUnit", unit);
    setCustomValue("priceUnit", currency);
  }, [unit, currency]);
  const { getAll } = useCountries();
  const category = watch("category");
  const categorySlug = watch("categorySlug");
  const location = watch("location");
  const cityWatch = watch("city") || { name: "" };
  const addressWatch = watch("address");
  const roomCount = watch("roomCount");
  const bathroomCount = watch("bathroomCount");
  const guestCount = watch("guestCount");
  const bedCount = watch("bedCount");
  const title = watch("title");
  const description = watch("description");
  const price = watch("price");
  const size = watch("size");
  const priceNegotiable = watch("priceNegotiable");
  const propertyPrice = watch("propertyPrice");
  const locationCoords = watch("locationCoords");
  const airbnb = watch("airbnb");
  const booking = watch("booking");
  const other = watch("other");
  const sizeUnit = watch("sizeUnit");
  const priceUnit = watch("priceUnit");
  const packageId = watch("packageId");
  const offerId = watch("offerId");
  useEffect(() => {
    const allCountries = getAll();
    const country = allCountries.find((country) => country.label == location?.label);
    //@ts-ignore
    setSelectedLocation(country);
  }, [location]);

  const onBack = () => {
    setStep((value) => value - 1);
  };

  const onNext = () => {
    setStep((value) => value + 1);
  };

  const onSubmit = async (data: FormD) => {
    if (step !== STEPS.ADS) {
      return onNext();
    }

    const formData = new FormData();
    images.forEach((image: any, index: number) => {
      formData.append(`images`, image);
    });
    formData.append("category", category);
    formData.append("title", title);
    formData.append("categoryslug", categorySlug);
    formData.append("roomCount", roomCount.toString());
    formData.append("bathroomCount", roomCount.toString());
    formData.append("guestCount", guestCount.toString());
    formData.append("bedCount", bedCount.toString());
    formData.append("countryCode", location?.value || "");
    formData.append("city", location?.label || "");
    formData.append("stateName", cityWatch?.name.toString() || "");
    formData.append("address", addressWatch);
    formData.append("latlng", location?.latlang || "");
    //@ts-ignore
    formData.append("images", images);
    formData.append("price", price.toString());
    formData.append("priceNegotiable", priceNegotiable.toString());
    formData.append("propertyPrice", propertyPrice.toString());
    formData.append("description", description || "");
    formData.append("size", size || "");
    formData.append("lat", locationCoords[0].toString());
    formData.append("lng", locationCoords[1].toString());
    formData.append("airbnb", airbnb);
    formData.append("booking", booking);
    formData.append("other", other);
    formData.append("sizeUnit", sizeUnit);
    formData.append("priceUnit", priceUnit);
    formData.append("packageId", packageId.toString());
    formData.append("offerId", offerId.toString());
    const selectedAmenityIds = selectedAmenities.map((label) => {
      const matchingAmenity = amenities.find((amenity) => amenity.label === label);
      return matchingAmenity ? matchingAmenity.id : null;
    });
    for (let i = 0; i < selectedAmenities.length; i++) {
      //@ts-ignore
      formData.append("amenities[]", selectedAmenityIds[i]?.toString());
    }

    setIsLoading(true);
    try {
      const res: AxiosResponse = await axios.post("/listing/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token") || "",
        },
      });

      toast.promise(
        Promise.resolve(res),
        {
          loading: "Creating...",
          success: (
            <b>
              Listing successfully created! Our team will take a look at the listing and if
              everything is OK, we will approve it.
            </b>
          ),
          error: <b>Something went wrong.</b>,
        },
        { duration: 6000 }
      );

      router(0);
      setStep(STEPS.CATEGORY);
      reset();
      propertyPost.onClose();
    } catch (error) {
      console.error(error);
    } finally {
      reset();
      setSelectedAmenities([]);
      setSelectedAddress("");
      setIsLoading(false);
    }
  };
  const isValidUrl = (url: string | undefined): boolean => {
    if (!url) return true;
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const linkValidationRules = {
    airbnb: {
      validate: {
        validLink: (value: string | undefined) =>
          isValidUrl(value) || "Please enter a valid Airbnb link",
      },
    },
    booking: {
      validate: {
        validLink: (value: string | undefined) =>
          isValidUrl(value) || "Please enter a valid Booking.com link",
      },
    },
    other: {
      validate: {
        validLink: (value: string | undefined) => isValidUrl(value) || "Please enter a valid link",
      },
    },
  };
  const checkNextDisabled = useMemo(() => {
    if (step === STEPS.CATEGORY && category.length >= 1 && title.length > 3) {
      return false;
    }
    if (step === STEPS.AMENITIES && selectedAmenities.length >= 1) {
      return false;
    }
    if (
      step === STEPS.LOCATION &&
      location?.label &&
      addressWatch.length > 3 &&
      selectedCity.name.length > 2
    ) {
      return false;
    }
    if (step === STEPS.INFO) {
      return false;
    }
    if (step === STEPS.DESCRIPTION && description.length >= 5) {
      return false;
    }
    if (step === STEPS.LINKS) {
      return false;
    }
    if (step === STEPS.IMAGES && images.length >= 3) {
      return false;
    }
    if (step === STEPS.PRICE && (price > 0 || priceNegotiable === true) && Number(size) >= 3) {
      return false;
    }
    if (step === STEPS.ADS) {
      return false;
    }
    return true;
  }, [
    category,
    description,
    location,
    cityWatch,
    addressWatch,
    images,
    price,
    size,
    step,
    priceNegotiable,
    title,
    activeItem,
    propertyPost.isOpen,
  ]);

  const actionLabel = useMemo(() => {
    if (step === STEPS.ADS) {
      return "Create";
    }

    return "Next";
  }, [step]);

  const secondaryActionLabel = useMemo(() => {
    if (step === STEPS.CATEGORY) {
      return undefined;
    }
    return "Back";
  }, [step]);

  let bodyContent: JSX.Element | null = null;

  if (step === STEPS.CATEGORY) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Choose the property's type"} subtitle={"Choose from the following"} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-h-[50vh] overflow-y-auto">
          {categories.map((item: any) => (
            <div key={item.label} className="col-span-1">
              <CategoryInput
                onClick={(label: string, slug: string) => {
                  setCustomValue("category", label);
                  setCustomValue("categorySlug", slug);
                }}
                selected={category === item.label}
                label={item.label}
                slug={item.slug}
                icon={item.icon}
              />
            </div>
          ))}
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2"></span>
          <input
            id="title"
            disabled={isLoading}
            {...register("title", { required: true })}
            placeholder=" "
            type="text"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        pl-4
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      left-4
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Name of the property
          </label>
        </div>
      </div>
    );
  }
  if (step === STEPS.AMENITIES) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading
          title={"Choose the property's available amenities"}
          subtitle={"Choose from the following"}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-h-[50vh] overflow-y-auto">
          {amenities.map((item: any) => (
            <div key={item.label} className="col-span-1">
              <AmenitiesInput
                onClick={(label: string, slug: string) => toggleAmenity(label, slug)}
                selected={selectedAmenities.includes(item.label)}
                label={item.label}
                slug={item.slug}
                icon={item.icon}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (step === STEPS.LOCATION) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Where is the property located"} subtitle={"Choose location"} />
        <CountrySelect
          value={location}
          onChange={(value: any) => {
            setCustomValue("location", value);
            setSelectedLocation(value);
            setSelectedCity({ name: "Select a city", coords: [1, 1] });
            setValue("locationCoords", value.latlang);
          }}
        />
        {selectedLocation?.latlang[0] > 2 ? (
          <CitySelect
            value={cityWatch}
            country={selectedLocation}
            onChange={(value: any) => {
              setCustomValue("city", value);
              setSelectedCity(value);
            }}
          />
        ) : (
          ""
        )}
        <AddressInput
          value={selectedAddress}
          onChange={(value: any) => {
            setCustomValue("address", value);
            setSelectedAddress(value);
          }}
        />
        <Map
          key={`${selectedLocation?.latlang[0]}-${selectedLocation?.latlang[1]}`}
          center={locationCoords}
          onLocationChange={(loc) => {
            setCustomValue("locationCoords", loc);
          }}
          onCountryChange={(cntr) => {
            setCustomValue("location", {
              //@ts-ignore
              label: cntr,
            });
            setSelectedCity({ name: "Select a city", coords: [1, 1] });
          }}
        />
      </div>
    );
  }

  if (step === STEPS.INFO) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Room and guest information"} subtitle={"Rooms, guests and beds"} />
        <Counter
          title="Rooms"
          subtitle="How many rooms does the property have?"
          value={roomCount}
          onChange={(value: any) => {
            setCustomValue("roomCount", value);
          }}
        />
        <Counter
          title="Bathrooms"
          subtitle="How many bathrooms does the property have?"
          value={bathroomCount}
          onChange={(value: any) => {
            setCustomValue("bathroomCount", value);
          }}
        />
        <Counter
          title="Guests"
          subtitle="How many guests does the property allow?"
          value={guestCount}
          onChange={(value: any) => {
            setCustomValue("guestCount", value);
          }}
        />
        <Counter
          title="Beds"
          subtitle="How many beds does the property have?"
          value={bedCount}
          onChange={(value: any) => {
            setCustomValue("bedCount", value);
          }}
        />
      </div>
    );
  }

  if (step === STEPS.IMAGES) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Upload images of the property"} subtitle={"Upload images"} />
        <ImageUpload />
      </div>
    );
  }

  if (step === STEPS.DESCRIPTION) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Describe the property"} subtitle={"Enter a description"} />
        <TextArea
          id="description"
          label="Description"
          type="text"
          value={description}
          disabled={isLoading}
          register={register}
          errors={errors}
          required
        />
      </div>
    );
  }
  if (step === STEPS.LINKS) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Booking site links"} subtitle={"Enter other booking site links"} />

        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">
            <FaAirbnb />
          </span>
          <input
            id="airbnb"
            disabled={isLoading}
            {...register("airbnb", linkValidationRules.airbnb)}
            placeholder=" "
            type="text"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Airbnb.com Link
          </label>
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">
            <TbBrandBooking />
          </span>
          <input
            id="booking"
            disabled={isLoading}
            {...register("booking", linkValidationRules.booking)}
            placeholder=" "
            type="text"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Booking.com Link
          </label>
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">
            <CiLink />
          </span>
          <input
            id="other"
            disabled={isLoading}
            {...register("other", linkValidationRules.other)}
            placeholder=" "
            type="text"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Other Link
          </label>
        </div>
      </div>
    );
  }

  if (step === STEPS.PRICE) {
    bodyContent = (
      <div className="flex flex-col gap-8">
        <Heading title={"Prices and size"} subtitle={"Enter prices and size"} />
        <div className="flex justify-end w-full">
          <CurrencyDropdown onCurrencyChange={handleCurrencyChange} />
          <UnitDropdown onUnitChange={handleUnitChange} />
        </div>

        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">
            {currency === "USD" ? "$" : "€"}
          </span>
          <input
            id="price"
            disabled={isLoading || priceNegotiable}
            {...register("price", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Price per night
          </label>
        </div>
        <div className="w-full relative">
          <span className="w-6 h-6 text-neutral-700 absolute top-5 left-2">
            {currency === "USD" ? "$" : "€"}
          </span>
          <input
            id="propertyPrice"
            disabled={isLoading || priceNegotiable}
            {...register("propertyPrice", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
     
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Price for the property
          </label>
        </div>
        {/* <div className="flex items-center">
          <input
            id="priceNegotiable"
            type="checkbox"
            {...register("priceNegotiable")}
            className={`
            peer
            w-6 h-6
            border-2
            checked:bg-primary checked:border-primary
            rounded-md
            outline-none
            transition
            disabled:opacity-70
            disabled:cursor-not-allowed
          `}
          />
          <label
            className={`
            text-md
            duration-150
            transform
            pl-2
          `}
          >
            Цена по договаряне
          </label>
        </div> */}

        <div className="w-full relative">
          <span className="w-4 h-4 text-neutral-700 absolute top-2 left-2 text-s">{unit}</span>
          <input
            id="size"
            disabled={isLoading}
            {...register("size", { required: true })}
            placeholder=" "
            type="number"
            className={`
        peer
        w-full
        p-4
        pt-6
        font-semibold
        bg-white
        border-2
        rounded-md
        outline-none
        transition
        disabled:opacity-70
        disabled:cursor-not-allowed
        ${true ? "pl-9" : "pl-4"}
        `}
          />
          <label
            className={`
      absolute
      text-md
      duration-150
      transform
      -translate-y-3
      top-4
      z-10
      origin-[0]
      ${true ? "left-9" : "left-4"}
      peer-placeholder-shown:scale-100
      peer-placeholder-shown:translate-y-0
      peer-focus:scale-75
      peer-focus:-translate-y-4
      `}
          >
            Size
          </label>
        </div>
      </div>
    );
  }
  if (step === STEPS.ADS) {
    bodyContent = (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <Heading
            title="Advertise listing"
            subtitle="Which type of advertising would you choose?"
          />
          <span className="flex gap-2 items-center">
            How it works
            <FaCircleInfo
              size={20}
              onClick={() => {
                setAdvertismentInfoOpen(true);
              }}
              className="info-icon text-blue-500 cursor-pointer flex justify-end group-hover:opacity-100"
            />
            {advertismentInfoOpen && (
              <InfoModal
                onClick={() => {
                  setAdvertismentInfoOpen(false);
                }}
                text={`Please choose the package that best suits your needs, and once your listing has been approved, you can proceed with payment (if its any of the paid packages) for the chosen package.`}
              />
            )}
          </span>
        </div>
        <span className="text-sm font-bold">{currentlySelectedPackage}</span>
        {adItems.map((item) => (
          <div key={item.id} className="mb-4 cursor-pointer">
            <button
              className="flex cursor-pointer justify-between w-full bg-white rounded-lg p-4 shadow-md hover:shadow-lg focus:outline-none focus:ring focus:ring-blue-300"
              onClick={() => handleClick(item.id)}
            >
              <span className="text-lg">{item.name}</span>
              <span>{activeItem === item.id ? "-" : "+"}</span>
            </button>
            {activeItem === item.id && (
              <div>
                <div className="bg-white rounded-lg p-4 shadow-md mt-5 animate-appear flex justify-between">
                  <div className="flex flex-col">
                    {item.id !== "free" && (
                      <div className="flex gap-2 mb-2">
                        What is a "{item.name}" listing{" "}
                        <FaCircleInfo
                          size={20}
                          onClick={() => {
                            setOfferInfoModalOpen(true);
                          }}
                          className="info-icon text-blue-500 cursor-pointer"
                        />
                      </div>
                    )}
                    {item.data.map((offer) => (
                      <div key={offer.id} className="flex justify-between items-center mb-2">
                        <form className="flex items-center">
                          <label
                            key={offer.id}
                            className="flex justify-between items-center mb-2 cursor-pointer"
                          >
                            <input
                              type="radio"
                              className="form-radio h-5 w-5 text-[#06b6d4] outline-none focus:outline-none biorde" // Add focus:outline-none
                              name={`offer_${item.id}`}
                              value={offer.id.toString()}
                              onChange={(e) => handleRadioChange(e, item.id)}
                              checked={selectedOffer[item.id] === offer.id}
                            />
                            <div className="flex w-full items-between">
                              {item.data[0].days > 0 && (
                                <span className="ml-2">{offer.days} days</span>
                              )}
                              <span className="ml-2 justify-end">
                                ${offer.price.toFixed(2)}{" "}
                                {item.data[0].days > 0 &&
                                  `($${(offer.price / offer.days).toFixed(2)} /day)`}
                              </span>
                            </div>
                          </label>
                        </form>
                      </div>
                    ))}
                    {item.id !== "free" ? (
                      <div className="flex gap-2 items-center">
                        <span className="font-bold">Bonus: </span>{" "}
                        <span className="">
                          First {item.name.toUpperCase()} listing every {item.bonusDaysFirstListing}{" "}
                          days
                        </span>
                        <FaCircleInfo
                          size={20}
                          onClick={() => {
                            setInfoModalOpen((value) => !value);
                          }}
                          className="info-icon text-blue-500 cursor-pointer flex justify-end group-hover:opacity-100"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {infoModalOpen && (
                      <InfoModal
                        onClick={() => {
                          setInfoModalOpen(false);
                        }}
                        text={`Every ${item.bonusDaysFirstListing} days the listing automatically becomes a "First ${item.name}"
                        if the listing is still ${item.name}. This happens at no cost.`}
                      />
                    )}
                    <button
                      onClick={() => {
                        handlePurchase(item.id, Number(selectedOffer[item.id]) - 1);
                        if (item.id === "free") {
                          setCurrentlySelectedPackage(`Currently selected: Package ${item.name}`);
                          toast.success(`Package ${item.name} selected`);
                        } else {
                          toast.success(
                            `Package ${item.name} selected for ${
                              item.data[Number(selectedOffer[item.id]) - 1].days
                            } days `
                          );
                          setCurrentlySelectedPackage(
                            `Currently selected: Package ${item.name} for ${
                              item.data[Number(selectedOffer[item.id]) - 1].days
                            } days `
                          );
                        }
                      }}
                      disabled={!selectedOffer[item.id]}
                      className="bg-[#06b6d4] hover:scale-105 text-white px-4 py-2 rounded-lg mt-3 disabled:bg-gray-500 disabled:cursor-not-allowed"
                    >
                      Select
                    </button>
                  </div>

                  {offerInfoModalOpen && (
                    <PackageInformationModal
                      onClick={() => {
                        setOfferInfoModalOpen(false);
                      }}
                      typeOfPackage={item.name}
                      paragraphs={item.perks}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
  return (
    <Modal
      isOpen={propertyPost.isOpen}
      onClose={propertyPost.onClose}
      onSubmit={handleSubmit(onSubmit)}
      actionDisabled={checkNextDisabled}
      actionLabel={actionLabel}
      secondaryActionLabel={secondaryActionLabel}
      secondaryAction={step === STEPS.CATEGORY ? undefined : onBack}
      title="Create listing"
      body={bodyContent}
      footer=""
      disabled={isLoading}
    />
  );
};

export default PropertyPostModal;
