import React from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

const ComingSoonPage = () => {
  return (
    <div className="nc-PageJoinUs overflow-hidden relative" data-nc-id="PageJoinUs">
      <Helmet>
        <title>Coming Soon - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            Exciting things are coming soon to TryBuy.club!
          </h2>

          <div className="space-y-8 lg:space-y-12">
            <p>
              Our team is working hard to bring you new features, resources, and opportunities.
              Stay tuned for updates!
            </p>
          </div>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageJoinUs_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default ComingSoonPage;
