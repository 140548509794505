import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW2-1.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/HIW2-3.png";
import VectorImg from "images/VectorHIW.svg";
import usePropertyListModal from "hooks/usePropertyListModal";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
    onClk?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Browse Properties",
    desc: "Explore our diverse range of properties available for trial.",
    onClk: "browse",
  },
  {
    id: 2,
    img: HIW2img,
    title: "List Property",
    desc: "Unlock the full potential of your property listings by joining TryBuy.club.",
    onClk: "list",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Become Official Country Partner",
    desc: "Drive local engagement and grow with us as we expand globally!",
    onClk: "become",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "", data = DEMO_DATA }) => {
  const listProperty = usePropertyListModal();
  const navigate = useNavigate();
  return (
    <div className={`nc-SectionHowItWork  ${className}`} data-nc-id="SectionHowItWork">
      <Heading isCenter desc="TryBuy">
        How it works
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img className="hidden md:block absolute inset-x-0 top-10" src={VectorImg} alt="" />
        {DEMO_DATA.map((item) => (
          <div key={item.id} className="relative flex flex-col items-center max-w-xs mx-auto">
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : item.onClk ? (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto cursor-pointer"
                src={item.img}
              />
            ) : (
              <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={item.img} />
            )}
            <div className="text-center mt-auto">
              <ButtonPrimary
                className={`${item.onClk === "" ? "mb-6" : ""}`}
                onClick={() => {
                  if (item.onClk === "list") {
                    listProperty.onOpen();
                  } else if (item.onClk === "become") {
                    navigate("/contacts");
                  } else {
                    navigate(
                      "/listings?state=&location=&lat=undefined&lng=undefined&minPrice=10000&maxPrice=10000000"
                    );
                  }
                }}
              >
                {item.title}
              </ButtonPrimary>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">{item.desc}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
