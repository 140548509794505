import React, { FC } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { Listing } from "Types/Listing";
import NcImage from "shared/NcImage/NcImage";
import { IMAGE_URL } from "config/config";

export interface StayCardProps {
  className?: string;
  data: Listing;
  size?: "default" | "small";
}

const StayCardPopup: FC<StayCardProps> = ({ size = "default", className = "", data }) => {
  const { galleryImgs, listingCat, address, price, id, name, bedCount, bathroomCount, guestCount } =
    data;
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <a href={`/listings/${id}`} target="_blank">
          <img src={IMAGE_URL + galleryImgs.split(",")[0]} alt="" />
        </a>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <a href={`/listings/${id}`} target="_blank">
        <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
          <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {listingCat} · {bedCount} beds · {bathroomCount} baths
            </span>
            <div className="flex items-center space-x-2">
              <h2
                className={` font-medium capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{name}</span>
              </h2>
            </div>
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              {size === "default" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
              <span className="">{address}</span>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {price} €{` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  / property price
                </span>
              )}
            </span>
          </div>
        </div>
      </a>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCardPopup;
