import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageJoinUsProps {
  className?: string;
}

const PageJoinUs: FC<PageJoinUsProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageJoinUs overflow-hidden relative ${className}`} data-nc-id="PageJoinUs">
      <Helmet>
        <title>Join Us - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            At TryBuy.club, we're dedicated to creating a vibrant community of real estate
            professionals who thrive in a dynamic and ever-evolving industry. By becoming a partner
            with us, you'll gain access to a wealth of opportunities and resources designed to
            support your growth and success.
          </h2>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Work and Live Anywhere
            </h3>
            <p>
              In today's interconnected world, we recognize that traditional office boundaries no
              longer define where or how work gets done. That's why we offer our partners the
              freedom to work from anywhere in the world, within the bounds of local regulations.
              Whether you prefer the hustle and bustle of urban life or the tranquility of a rural
              setting, you'll find the flexibility you need to thrive.
            </p>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Create Meaningful Impact
            </h3>
            <p>
              Join our global creative community, where passion and collaboration fuel innovation
              that shapes the world. By working alongside industry-leading talent, you'll have the
              opportunity to make a real difference and leave your mark on the real estate
              landscape. Our vibrant network aims to encompass individuals from all walks of life,
              united by a shared commitment to excellence and a drive to succeed.
            </p>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Unlock Your Next Opportunity as a TryBuy.club Partner
            </h3>
            <ul className="list-disc list-inside">
              <li>
                Real Estate Agents and Brokers: Connect with motivated buyers and sellers, and
                showcase your expertise to a global audience.
              </li>
              <li>
                Real Estate Agencies: Expand your reach and grow your business by accessing our
                platform's extensive network of clients and partners.
              </li>
              <li>
                Property Management Companies: Streamline your operations and attract new clients by
                leveraging our cutting-edge tools and resources.
              </li>
              <li>
                Photographers: Showcase properties in their best light with stunning photography
                that captures the imagination of potential buyers.
              </li>
              <li>
                Designers: Transform spaces and create unforgettable experiences that inspire and
                delight homeowners and buyers alike.
              </li>
              <li>
                Decorators: Elevate interiors with curated design solutions that enhance
                functionality, aesthetics, and overall appeal.
              </li>
            </ul>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              What You'll Receive as Our Partner
            </h3>
            <ul className="list-disc list-inside">
              <li>
                Exclusive Access to Potential Clients: Gain priority access to our targeted audience
                actively seeking real estate through our platform. Our advanced search and
                recommendation algorithms will match your listings with the right buyers and
                renters, ensuring maximum visibility and engagement.
              </li>
              <li>
                Promotion and Visibility: Showcase your profiles or listings in a dedicated section
                for partners, increasing your visibility to a larger audience. Our marketing team
                will work tirelessly to promote your brand and listings through targeted advertising
                campaigns, social media outreach, and strategic partnerships.
              </li>
              <li>
                Training and Support: Access training sessions, seminars, or online resources to
                enhance your skills in the real estate industry, along with ongoing support from our
                team. Whether you're a seasoned professional or just starting out, we're here to
                provide the guidance and assistance you need to succeed.
              </li>
              <li>
                Special Offers and Discounts: Enjoy special offers and discounts, such as reduced
                listing fees or preferred status when selecting advertising packages. As a valued
                partner, you'll have access to exclusive perks and benefits that are designed to
                help you save time and money while maximizing your earning potential.
              </li>
              <li>
                Collaboration and Partnership Opportunities: Participate in special collaboration
                programs, including joint marketing campaigns, events, or business development
                projects. By joining forces with other like-minded professionals, you'll have the
                opportunity to leverage each other's strengths and expertise to achieve mutual
                success.
              </li>
            </ul>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <p>Join us today and unlock your full potential as a real estate professional!</p>
          </div>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageJoinUs_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageJoinUs;
