import { AdItem } from "components/Modals/PropertyPostModal";
import create from "zustand";

interface AdPackagePayModalState {
  isOpen: boolean;
  adItemName: string;
  daysRemaining: number;
  listingId: number;
}

interface AdPackagePayModalActions {
  onOpen: () => void;
  onClose: () => void;
  setAdItemName: (adItemName: string) => void;
  setDaysRemaining: (daysRemaining: number) => void;
  setListingId: (listingId: number) => void;
}

const useAdPackageExtendModal = create<AdPackagePayModalState & AdPackagePayModalActions>(
  (set) => ({
    isOpen: false,
    daysRemaining: 0,
    listingId: 0,
    adItemName: "free",
    onOpen: () => set({ isOpen: true }),
    onClose: () => set({ isOpen: false }),
    setAdItemName: (adItemName: string) => set({ adItemName }),
    setDaysRemaining: (daysRemaining: number) => set({ daysRemaining }),
    setListingId: (listingId: number) => set({ listingId }),
  })
);

export default useAdPackageExtendModal;
