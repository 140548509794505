import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionHeroLeft from "./SectionHeroLeft";
import SectionStatisticSellers from "./SectionStatisticSellers";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
      <Helmet>
        <title>About - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us"
          btnText=""
          subHeading="At TryBuy.club, we revolutionize the way properties are discovered, experienced, and purchased. Our platform offers a unique proposition: Try Before You Buy. Imagine stepping into your potential future home, feeling its ambiance, and envisioning your life within its walls before making the big decision."
        />
        <SectionStatistic />
        <SectionStatisticSellers />
        <SectionHeroLeft
          rightImg={rightImg}
          heading="For both Property Buyers and Sellers"
          btnText=""
          subHeading="Remember, TryBuy.club is your exclusive portal for this innovative service. It offers a practical and sensible approach to property transactions, allowing buyers to experience their potential future homes firsthand and sellers to showcase their listings effectively while earning rental income.

          By embracing Try Before You Buy, sellers gain a competitive edge in the market, enticing the most discerning buyers with the promise of a fully immersive property experience.
          
          Experience the future of property transactions with TryBuy.club – where exploration meets investment, and every visit is a step closer to home."
        />
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageAbout;
