import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageBuyerGuidesProps {
  className?: string;
}

const PageBuyerGuides: FC<PageBuyerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageBuyerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageBuyerGuides"
    >
      <Helmet>
        <title>Buyer Guides - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            1. The Advantages of Buying
          </h2>
          <p>Buying your own property has a number of advantages however, it’s not for everyone.</p>
          <p>
            Before deciding whether buying is right for you, make sure you consider the benefits and
            disadvantages.
          </p>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Benefits to buying:</h3>
          <ul className="list-disc list-inside">
            <li>
              It gives you a better level of control over your home and the ability to make
              improvements as you see fit.
            </li>
            <li>
              If your property increases in value, perhaps through market conditions or improvements
              made, the gains will be yours.
            </li>
            <li>
              Increasing the value of your home creates equity (the property value less the debt you
              owe) and allows you to trade up to on your next move.
            </li>
            <li>
              Monthly mortgage repayments can be very competitive compared to what you would pay to
              rent a comparable property, and potentially even cheaper.
            </li>
            <li>
              The monthly payments you make all contribute towards eventually paying off the
              mortgage and you owning the property outright.
            </li>
            <li>
              Owning a property outright means that you can reduce your living costs as there will
              be no rent or mortgage to pay, especially useful later in life.
            </li>
            <li>
              In retirement, there are schemes that allow you to release equity from your home to
              help fund your retirement.
            </li>
          </ul>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Disadvantages to buying:
          </h3>
          <ul className="list-disc list-inside">
            <li>
              Buying a property requires you to have a deposit normally of at least 5% of the
              purchase price to put down.
            </li>
            <li>
              There are additional costs of buying which include, but are not limited to: Mortgage
              application fees, Legal costs, Property surveys.
            </li>
            <li>
              During the purchase process, you may find out something about the property which stops
              you buying it and any money you have spent on the costs above are likely to be lost.
            </li>
            <li>
              When you rent a property, the landlord is responsible for maintaining it for you, when
              you own it you have the make and pay for these yourself.
            </li>
            <li>
              Whilst house price values generally increase, this is not the case everywhere and
              price increases are not guaranteed.
            </li>
            <li>
              Moving home will take more effort and time if you need to sell your current home and
              purchase a new one.
            </li>
          </ul>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            2. Steps to Buying a Property
          </h2>
          <p>
            If you decide that buying a property is the right thing for you to do, it’s useful to
            understand the process and what lies ahead.
          </p>
          <p>
            This guide will take you through all the steps involved in buying a property and help
            you through the maze of options.
          </p>
          <p>A simple timeline of what needs to be done in the process is as follows:</p>
          <ol className="list-decimal list-inside">
            <li>Decide what sort of property you want to purchase.</li>
            <li>
              Work out how much money you will be able to spend by getting a mortgage agreement in
              principle.
            </li>
            <li>
              Start viewings to find your dream property - you can search here for properties for
              sale that can be rented for a trial: www.trybuy.club.
            </li>
            <li>Find a specialist to do the legal work for you.</li>
            <li>Submit offers on the right property with aim of agreeing a sale.</li>
            <li>Submit your mortgage application.</li>
            <li>Arrange for a survey to be done to check the condition of the property.</li>
            <li>Wait for your confirmed mortgage offer to arrive.</li>
            <li>Send the deposit funds.</li>
            <li>
              Exchange contracts which binds both parties to the contracts and set the completion
              date.
            </li>
            <li>Completion and the property is now yours.</li>
          </ol>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            How to Find Property to Buy
          </h2>
          <p>
            With most properties now being listed online, it’s easier than ever to find a property
            to buy that meets your needs. However, because there is such a large selection to choose
            from, it’s worth taking the time to ask yourself exactly what you’re looking for before
            you seriously start looking; this will help you to firm up your requirements and narrow
            the options more efficiently.
          </p>
          <p>
            The key criteria you’ll need to think about before embarking on your property search:
          </p>
          <ul className="list-disc list-inside">
            <li>
              Price – how much can you realistically afford to spend per month on a mortgage and
              bills?
            </li>
            <li>Location – where do you want to buy?</li>
            <li>Size – how many bedrooms do you need? Do any of them need to be doubles?</li>
          </ul>
          <p>
            Before you start looking at properties you’ll need an idea of many bedrooms you require,
            your budget and a shortlist of locations you’re interested in.
          </p>
          <p>To make this part of the process easier, ask yourself the following questions:</p>
          <ul className="list-disc list-inside">
            <li>
              How important are transport links for you? Are there any you need to be particularly
              near to?
            </li>
            <li>How long a commute would you be comfortable with?</li>
            <li>How far are you prepared to travel to meet friends and family?</li>
            <li>What do you love about your current home/area?</li>
            <li>
              What are the things you don’t like about your current home that you would like to
              avoid next time?
            </li>
            <li>What are the ‘must haves’ that will make your new property feel like home?</li>
            <li>What amenities do you want or need on your door step?</li>
            <li>How long do you plan to stay in the next property?</li>
            <li>If you have hobbies, is there somewhere you need to get to regularly?</li>
          </ul>
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            1. The Advantages of Buying
          </h2>
          <p>Buying your own property has a number of advantages however, it’s not for everyone.</p>
          <p>
            Before deciding whether buying is right for you, make sure you consider the benefits and
            disadvantages.
          </p>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Benefits to buying:</h3>
          <ul className="list-disc list-inside">
            <li>
              It gives you a better level of control over your home and the ability to make
              improvements as you see fit.
            </li>
            <li>
              If your property increases in value, perhaps through market conditions or improvements
              made, the gains will be yours.
            </li>
            {/* Add more benefits here */}
          </ul>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Disadvantages to buying:
          </h3>
          <ul className="list-disc list-inside">
            <li>
              Buying a property requires you to have a deposit normally of at least 5% of the
              purchase price to put down.
            </li>
            <li>
              There are additional costs of buying which include, but are not limited to: mortgage
              application fees, legal costs, property surveys.
            </li>
            {/* Add more disadvantages here */}
          </ul>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Steps to buying a property:
          </h3>
          {/* Content for the Steps to Buying a Property */}
        </div>

        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            2. Searching for Property
          </h2>
          <p>
            Once you’re clear on your requirements, you’re ready to start looking at properties for
            sale.
          </p>
          <p>
            TryBuy.club works with many property sellers giving the opportunity to rent the property
            and experience the stay there before you buy it. This makes it a great place to start
            your search.
          </p>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">TryBuy top tips:</h3>
          <ul className="list-disc list-inside">
            <li>Download our iPhone, iPad or Android app so to search on the go.</li>
            <li>
              Create a TryBuy account, so that your search activity is synced across all your
              devices.
            </li>
            <li>
              If you’re clear on your criteria, start a search now:{" "}
              <a href="https://www.trybuy.club" className="text-blue-600 hover:underline">
                www.trybuy.club
              </a>
            </li>
          </ul>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
            Getting the Most out of Trying Before Buying Property
          </h3>
          <p>
            Use TryBuy to find out as much as you can about a property you like using the
            photographs, floorplans and local information that is available with most descriptions.
            All of this information will help you make a decision but visiting and experiencing the
            property is critical. As soon as you have found a property that is of interest, book a
            stay with the host in trybuy.club, or the alternative contacts given by the host in the
            listing. When you rent the property you see and experience everything in details -
            location, transport connections, quarter characteristics, property condition, amenities
            condition, neighbors, even noises, smells etc.
          </p>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Types of hosting</h3>
          <p>Direct Vendor hosting:</p>
          {/* Content for Direct Vendor hosting */}
          <p>Hosting through agent:</p>
          {/* Content for Hosting through agent */}
          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">
            A few things to consider when trying a property are:
          </h4>
          <ul className="list-disc list-inside">
            <li>
              When renting you can spend all the day and night in the property - try and view the
              property at different times of the day.
            </li>
            <li>
              It might be very different at night or when all of the neighbors are at home or if you
              are close to a busy road or train line.
            </li>
            {/* Add more considerations here */}
          </ul>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Remember:</h2>
          <p className="text-lg lg:text-xl mb-4">
            Be Ready – knowing how much you can afford and being able to supply the evidence to
            confirm it (your mortgage agreement in principle) will show that you are a reliable,
            organised and serious buyer.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Chain free – First time buyers, buyers with no chain and buyers who have pre-arranged
            mortgages will often have a head start on most of the competition, especially where the
            seller is in a chain.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Speed – if you are chain free and organised you should be able to complete the
            transaction quickly. Be sure to check if the current owners are in a hurry to sell or
            have been trying to sell for a long time. If so, they may be willing to accept a lower
            offer to make the sale.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Price – Some vendors are not in a hurry and simply want to maximise the price that they
            can achieve. Sellers who are not in a hurry to move are more likely to hold out for a
            higher price or asking price offer.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Be confident – Even if your offer is not at the asking price, don’t apologise. The offer
            is what you are prepared to pay for the property.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            If your initial offer is not successful, there could be a couple of rounds of offers and
            counter-offers back before an agreement is made.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Knowing your budget ceiling is crucial so decide your maximum limit from the start and
            stand firm. If the seller refuses to budge, you need to think very carefully if the
            property really is worth the extra money and of course, what you will have to live
            without over the long term.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Do your homework and check what the property is truly worth. See what the competition is
            like now. If there are few similar properties for sale in the area, chances are the
            seller has the upper-hand. Also, if there are any faults or repair work required, use
            this to justify a lower offer. In tougher times when there are fewer buyers, sellers may
            be more willing to negotiate on price.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Once you make an offer make it clear that it is:
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>
              Subject to contract (STC) – the final sale takes place only when lawyers have
              exchanged legally binding documents
            </li>
            <li>
              Subject to survey – this allows for the cost of any faults or issues to be taken into
              account once your surveyor has checked the property out
            </li>
          </ul>
          <p className="text-lg lg:text-xl mb-4">
            Once your offer has been accepted, make sure the vendor has taken the property off the
            market and is no longer advertising it for viewings.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            If someone else is interested and views it, you risk someone else coming in with a
            higher offer, which is known as gazumping.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            You should receive a letter confirming your offer. If you don’t receive this then make
            sure to ask for one.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Offer accepted, now what?</h2>
          <p className="text-lg lg:text-xl mb-4">
            Once the seller has accepted your offer, ask them to take it off the market. They don’t
            have to agree to this, but doing so will shut out other potential buyers.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Now you need to move fast – the seller will want to see progress so try to avoid any
            unnecessary delays in getting the surveys and other legal work done. If you’re buying
            with a mortgage, complete the application form and send the documents they require –
            this will include proof of your ID, evidence of your earnings, proof of your address
            over the last few months and your bank statements, so have these ready.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            The lender will then arrange for a valuation to be carried out on the property. This
            allows them to work out how fair the amount you’ve asked to borrow is compared to what
            they deem the property to be worth.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            If you’re lucky enough to not need a mortgage then you don’t have to get a survey done,
            though buying a property without one is not advisable.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            If you’re buying an older property, one that needs repairs or just for your own peace of
            mind, you should consider getting a more comprehensive survey carried out than the basic
            lender’s valuation.
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageBuyerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageBuyerGuides;
