import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "San Francisco, USA",
  },
  {
    title: "💌 EMAIL",
    desc: "contacts@trybuy.club",
  },
  {
    title: "☎ PHONE",
    desc: "+12093427780",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res: AxiosResponse = await axios.post(
        "/users/contact",
        { name: formData.name, email: formData.email, message: formData.message },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token") || "",
          },
        }
      );

      toast.promise(
        Promise.resolve(res),
        {
          loading: "Sending...",
          success: <b>Email successfully sent</b>,
          error: <b>Email not sent.</b>,
        },
        { duration: 5000 }
      );
    } catch (error) {
      toast.error("Email not sent");
    } finally {
      setFormData({ email: "", name: "", message: "" });
      setIsLoading(false);
    }
  };

  return (
    <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Contacts | TryBuy.club</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contacts
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    name="name"
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>
                  <Input
                    name="email"
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea
                    name="message"
                    className="mt-1"
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageContact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
