import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { Listing } from "Types/Listing";
import { adItems } from "components/Modals/PropertyPostModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ModalButton from "components/ModalButton";
import useAdPackagePayModal from "hooks/useAdPackagePayModal";
import useAdModal from "hooks/useAdModal";
import useAdPackageExtendModal from "hooks/useAdPackageExtendModal";

export interface StayCardProps {
  className?: string;
  data: Listing;
  size?: "default" | "small";
  isLoaded: boolean;
  isMyListings: boolean;
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  isLoaded,
  isMyListings,
}) => {
  //@ts-ignore
  const {
    galleryImgs,
    guestCount,
    roomCount,
    bathroomCount,
    bedCount,
    listingCat,
    address,
    categorySlug,
    name,
    size: propertySize,
    price,
    pricePerNight,
    id,
    sizeUnit,
    priceUnit,
    packageId,
    package_id,
    offer_id,
    isPaid,
    dateOfPayment,
    dateEndOfOffer,
    totalListings,
    listingPosition,
  } = data;
  const adPackagePayModal = useAdPackagePayModal();
  const adPackageExtendModal = useAdPackageExtendModal();
  const [remainingTime, setRemainingTime] = useState("");
  const adItem = adItems.find((item) => item.id === packageId);

  const adModal = useAdModal();
  const calculateRemainingTime = () => {
    if (isPaid === 1) {
      const dayOfPayment = new Date(dateOfPayment || Date.now());
      const endDateOfOffer = new Date(dateEndOfOffer || Date.now());
      endDateOfOffer.setHours(23, 59, 59, 999); // Set time to end of day to ensure correct comparison

      const currentDate = new Date();

      if (currentDate >= dayOfPayment && currentDate <= endDateOfOffer) {
        const timeDifference = endDateOfOffer.getTime() - currentDate.getTime();

        if (timeDifference <= 0) {
          setRemainingTime("");
        } else {
          let remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

          // If the current time is still within the first day, subtract one day
          if (currentDate < endDateOfOffer && timeDifference >= 24 * 60 * 60 * 1000) {
            remainingDays--;
          }

          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setRemainingTime(`${remainingDays}d ${hours}h ${minutes}m ${seconds}s`);
          return remainingDays;
        }
      } else {
        setRemainingTime("");
      }
    } else {
      setRemainingTime("Requires payment");
    }
    return 10;
  };

  useEffect(() => {
    calculateRemainingTime();
    const timerInterval = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(timerInterval);
  }, [isPaid, dateOfPayment, dateEndOfOffer]);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={galleryImgs}
          uniqueID={`StayCardH_${id}`}
          href={`/listings/${id}`}
        />
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCat} · {bedCount} beds · {bathroomCount} baths · {propertySize} {sizeUnit}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
          {adItem?.name && adItem.id !== "free" && (
            <Badge name={adItem?.name} color={adItem.badgeColor} />
          )}
          {adItem?.id !== "free" && isMyListings && (
            <div className="flex flex-col">
              <span className={`text-${adItem?.badgeColor}-700 font-bold text-md`}>
                Advertisment Status: {adItem?.name} package
              </span>{" "}
              {isPaid === 1 && adItem?.id !== "free" && isMyListings ? (
                <span>
                  {`Paid`}{" "}
                  <span className={`text-${adItem?.badgeColor}-700 font-bold text-lg`}>
                    {adItem?.name}
                  </span>{" "}
                  <span>Package for</span>{" "}
                  <span className={`text-${adItem?.badgeColor}-700 font-bold text-lg`}>
                    {adItem?.data[Number(offer_id)].days}{" "}
                  </span>{" "}
                  days.
                </span>
              ) : (
                <span className="text-lg font-bold text-orange-400">Requires payment</span>
              )}
              {isPaid === 1 && adItem?.id !== "free" && isMyListings ? (
                <div className="flex flex-col">
                  <span className="text-[#1f4c6f] text-md font-bold">Time Remaining</span>
                  <span className="text-[#1cbaa9] text-md font-bold">
                    {isPaid === 1 && remainingTime}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex flex-col">
          <span className="text-base font-semibold text-secondary-500">
            {pricePerNight} {priceUnit == "USD" ? "$" : "€"}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              / per night
            </span>
          </span>
          <span className="text-base font-semibold text-secondary-500">
            {price.toLocaleString()} {priceUnit == "USD" ? "$" : "€"}
            {` `}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              / property
            </span>
          </span>
        </div>
        {adItem?.id !== "free" && isPaid === 0 && isMyListings ? (
          <div className="flex flex-col gap-4">
            <ModalButton
              label={`Pay ${adItem?.name} Package for ${
                adItem?.data[Number(offer_id)].days
              } days ($${adItem?.data[Number(offer_id)].price.toFixed(2)})`}
              onClick={() => {
                adPackagePayModal.setListingId(id);
                adPackagePayModal.setAdItem(
                  adItem || {
                    id: "free",
                    name: "Free (No advertisment)",
                    bonusDaysFirstListing: 0,
                    badgeColor: "green",
                    data: [
                      {
                        id: 1,
                        days: 0,
                        price: 0.0,
                      },
                    ],
                    perks: [""],
                  }
                );
                adPackagePayModal.setOfferId(Number(offer_id));
                adPackagePayModal.onOpen();
              }}
            />
            {isMyListings && (
              <ModalButton
                label={`Change package`}
                onClick={() => {
                  adModal.setAdItem(
                    adItem || {
                      id: "free",
                      name: "Free (No advertisment)",
                      bonusDaysFirstListing: 0,
                      badgeColor: "green",
                      data: [
                        {
                          id: 1,
                          days: 0,
                          price: 0.0,
                        },
                      ],
                      perks: [""],
                    }
                  );
                  adModal.setListingId(id);
                  adModal.setOfferId(Number(offer_id));
                  adModal.onOpen();
                }}
              />
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {isMyListings && (
              <span>
                <Badge
                  name={`Current position: ${listingPosition} out of ${totalListings} listings`}
                  color={adItem?.badgeColor}
                />
              </span>
            )}
            {adItem?.id !== "free" && isMyListings && (
              <ModalButton
                label={`Extend ${adItem?.name} package`}
                onClick={() => {
                  const currentOffer = adItem?.id || "free";
                  const currentDaysRemaining = calculateRemainingTime();
                  adPackageExtendModal.setAdItemName(currentOffer);
                  adPackageExtendModal.setDaysRemaining(currentDaysRemaining);
                  adPackageExtendModal.setListingId(id);
                  adPackageExtendModal.onOpen();
                  // const desiredTotalDays = 12;

                  // const extensionPrice = calculateExtensionPrice(
                  //   currentOffer,
                  //   currentDaysRemaining,
                  //   desiredTotalDays
                  // );
                  // console.log(extensionPrice);
                }}
              />
            )}
            {isMyListings && (
              <ModalButton
                label={`${adItem?.id !== "free" ? "Change package" : "Advertise listing"}`}
                onClick={() => {
                  adModal.setAdItem(
                    adItem || {
                      id: "free",
                      name: "Free (No advertisment)",
                      bonusDaysFirstListing: 0,
                      badgeColor: "green",
                      data: [
                        {
                          id: 1,
                          days: 0,
                          price: 0.0,
                        },
                      ],
                      perks: [""],
                    }
                  );
                  adModal.setListingId(id);
                  adModal.setOfferId(Number(offer_id));
                  adModal.onOpen();
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return !isLoaded ? (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className} animate-pulse`}
      data-nc-id="StayCard"
    >
      <div className="invisible">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  ) : (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className} animate-appear `}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {renderContent()}
    </div>
  );
};

export default StayCard;
