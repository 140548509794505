import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { FaXTwitter, FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa6";
export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  {
    name: "Facebook",
    icon: FaFacebook,
    href: "https://www.facebook.com/share/FwGasRj2T9SJ5jEH/?mibextid=qi2Omg",
  },
  { name: "X", icon: FaXTwitter, href: "https://x.com/TryBuyClub?t=edzh0gVQW4SWKfNs5cbLDQ&s=09" },
  { name: "YouTube", icon: FaYoutube, href: "https://www.youtube.com/@TryBuyClub" },
  { name: "Instagram", icon: FaInstagram, href: "https://www.instagram.com/trybuy.club?igsh=MWZibTEyeHp4dmZyNw==" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <item.icon />
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
