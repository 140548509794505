import axios, { AxiosResponse } from "axios";
import { Listing } from "Types/Listing";

export default async function getListingById(id: string | number): Promise<Listing | null> {
  try {
    const response: AxiosResponse<Listing> = await axios.get(`/listing/${id}`, {
      headers: { Authorization: localStorage.getItem("token") },
    });

    const listingData = response.data;

    if (listingData.id <= 0) {
      return null;
    }
    return listingData;
  } catch (error: any) {
    throw new Error(error);
  }
}
