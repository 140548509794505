import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import PropertyCardH from "components/PropertyCardH/PropertyCardH";
import { Listing } from "Types/Listing";
import getListings from "actions/getListings";
import getFeaturedListings from "actions/getFeaturedListings";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridFeaturePropertyProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Explore Our Featured Listings",
  subHeading = "Discover TryBuy.club-Approved Listings",
  headingIsCenter,
  tabs = ["Apartments", "Houses", "Other"],
}) => {
  const [typeOfProperty, setTypeOfProperty] = useState<string>("1");
  const [isLoaded, setIsLoaded] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const [loadedListings, setLoadedListings] = useState(5);
  const [loadedCards, setLoadedCards] = useState(0);
  async function fetchListings(limit: number) {
    try {
      const listingsData = await getFeaturedListings(limit, typeOfProperty);
      if (listingsData?.data.properties.length >= 1) {
        setTimeout(() => {
          setIsLoaded(true);
        }, 50);
        setListings(listingsData?.data.properties);
      } else {
        setListings([]);
      }
    } catch (error) {
      setListings([]);
    }
  }

  useEffect(() => {
    fetchListings(loadedListings);
  }, [loadedListings, typeOfProperty]);

  useEffect(() => {
    if (isLoaded) {
      const interval = setInterval(() => {
        setLoadedCards((prevLoaded) => {
          const nextLoaded = prevLoaded + 1;
          return nextLoaded > listings.length ? listings.length : nextLoaded;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [isLoaded, listings]);
  const fetchMoreListings = () => {
    setLoadedListings((value) => Number(value) + 5);
  };
  const renderCard = (stay: Listing | Listing, index: number) => {
    return <PropertyCardH key={index} isLoaded={isLoaded} className="h-full" data={stay} />;
  };
  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <HeaderFilter
        tabActive={"Apartments"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={(tab) => {
          setIsLoaded(false);
          switch (tab) {
            case "Houses":
              setTypeOfProperty("0");
              break;
            case "Apartments":
              setTypeOfProperty("1");
              break;
            default:
              setTypeOfProperty("2");
          }
        }}
      />
      <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}>
        {listings.map(renderCard)}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary
          onClick={() => {
            fetchMoreListings();
          }}
          loading={!isLoaded}
        >
          Show more
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
