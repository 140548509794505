import React, { useCallback, useContext, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import useAccountMobileModal from "hooks/useAccountMobileModal";
import ModalButton from "components/ModalButton";
import { useNavigate } from "react-router-dom";
import useAdPackagePayModal from "hooks/useAdPackagePayModal";
import payAdPackage from "actions/payAdPackage";
import { UserContext } from "UserContext";

const PayAdPackage: React.FC = () => {
  const navigate = useNavigate();
  const adPackagePayModal = useAdPackagePayModal();
  const { user } = useContext(UserContext);
  const handleCheckout = () => {
    axios
      .post(
        "/advertisment/pay",
        {
          userId: user?.id,
          packageId: adPackagePayModal.adItem.id,
          packageName: adPackagePayModal.adItem.name,
          offerId: adPackagePayModal.offerId,
          listingId: adPackagePayModal.listingId,
        },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.loading("Redirecting to payment");
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const bodyContent = (
    <div className="flex flex-col gap-4">
      <Heading title="Advertisment package payment!" subtitle="" />
      <span className={`text-${adPackagePayModal.adItem?.badgeColor}-700 font-bold text-md`}>
        Package selected: {adPackagePayModal.adItem?.name}{" "}
        {adPackagePayModal.adItem.id !== "free" &&
          `for
        ${adPackagePayModal.adItem.data[adPackagePayModal.offerId].days} days`}{" "}
        ($
        {adPackagePayModal.adItem.data[adPackagePayModal.offerId].price.toFixed(2)})
      </span>{" "}
      {adPackagePayModal.adItem.id === "free" ? (
        <ModalButton
          label={`Downgrade`}
          onClick={async () => {
            try {
              await payAdPackage(
                adPackagePayModal.listingId,
                adPackagePayModal.adItem.id,
                adPackagePayModal.offerId
              );
              toast.success("Success.");
              adPackagePayModal.onClose();
              setTimeout(() => {
                navigate(0);
              }, 500);
            } catch (error) {
              toast.error("Error.");
            }
          }}
        />
      ) : (
        <ModalButton
          label={`${`Pay $${adPackagePayModal.adItem.data[adPackagePayModal.offerId].price.toFixed(
            2
          )} `}`}
          onClick={async () => {
            try {
              handleCheckout();
            } catch (error) {
              toast.error("Error.");
            }
          }}
        />
      )}
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
    </div>
  );

  return (
    <Modal
      disabled={false}
      isOpen={adPackagePayModal.isOpen}
      title="Package payment!"
      onClose={adPackagePayModal.onClose}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default PayAdPackage;
