import React, { FC } from "react";
import { AuthorType } from "data/types";
import { StarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { User } from "Types/User";

export interface CardAuthorBoxProps {
  className?: string;
  author: User;
  index?: number;
  isLoaded?: boolean;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({ className = "", author, index, isLoaded }) => {
  const { name, id } = author;
  return !isLoaded ? (
    <Link
      to={`/profile/${name}?id=${id}`}
      className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className} animate-pulse`}
      data-nc-id="CardAuthorBox"
    >
      <div className="invisible">
        {index && (
          <Badge
            className="absolute left-3 top-3"
            color={index === 1 ? "red" : index === 2 ? "blue" : "green"}
            name={`#${index}`}
          />
        )}
        <Avatar sizeClass="w-20 h-20 text-2xl" radius="rounded-full" userName={name} />
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1">{name}</span>
          </h2>
        </div>
        {/* <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
     <span className="text-xs font-medium pt-[1px]">{starRating || 4.9}</span>
     <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
   </div> */}
      </div>
    </Link>
  ) : (
    <Link
      to={"/"}
      className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className} animate-appear`}
      data-nc-id="CardAuthorBox"
    >
      {index && (
        <Badge
          className="absolute left-3 top-3"
          color={index === 1 ? "red" : index === 2 ? "blue" : "green"}
          name={`#${index}`}
        />
      )}
      <Avatar sizeClass="w-20 h-20 text-2xl" radius="rounded-full" userName={name} />
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">{name}</span>
        </h2>
      </div>
      {/* <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">{starRating || 4.9}</span>
        <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
      </div> */}
    </Link>
  );
};

export default CardAuthorBox;
