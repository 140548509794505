import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

export interface TermsPageProps {
  className?: string;
}

const TermsPage: FC<TermsPageProps> = ({ className = "" }) => {
  return (
    <div className={`TermsPage overflow-hidden relative ${className}`} data-nc-id="TermsPage">
      <Helmet>
        <title>Terms of Service - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <h1 className="text-3xl lg:text-5xl font-semibold text-center mb-8">Terms of Service</h1>

        <div className="max-w-3xl mx-auto">
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">I. General Provisions</h2>
          <p className="mb-4">
            These Terms of Service ("Terms") govern your use of the services provided by TryBuy.club
            (referred to as the "Administrator") through the TryBuy.club internet platform. By using
            any of the services offered by TryBuy.club, you agree to abide by these Terms, as well
            as the Privacy Policy and Cookie Policy of TryBuy.club. It is important that you read
            these Terms carefully. Your use of the services constitutes a legally binding contract
            between you and the Administrator.
          </p>
          <p className="mb-4">
            The Terms also describe how your personal data is processed in connection with the
            services provided by the Administrator. By agreeing to these Terms, you also consent to
            the Privacy Policy and the processing of your personal data in accordance with it.
          </p>
          <p className="mb-4">
            Even if registration is not required for certain services, by using the TryBuy.club
            internet platform or other related services where these Terms and the Privacy Policy are
            applicable, you are deemed to have read, understood, and agreed to them, as well as to
            the processing of your personal data.
          </p>
          <p className="mb-8">
            These Terms apply to the TryBuy.club website. If you do not agree to these Terms, please
            do not use the services provided by TryBuy.club.
          </p>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">II. Basic Definitions</h2>
          <p className="mb-4">
            <strong>Administrator:</strong> Refers to the internet platform TryBuy.club.
          </p>
          <p className="mb-4">
            <strong>User:</strong> Refers to any natural or legal person who uses any of the
            services provided by the Administrator. Individuals under the age of 18 should use these
            services under the supervision of a parent, guardian, or trustee.
          </p>
          <p className="mb-4">
            <strong>Services:</strong> Include but are not limited to access to information
            resources, creating user profiles, publishing ads, generating resumes, sending messages,
            posting comments, subscribing to new ads, promoting ads, and other opportunities
            provided by TryBuy.club.
          </p>
          <p className="mb-4">
            <strong>Group of individuals conducting joint activity:</strong> Refers to individuals
            sharing common access, address, contact information, or other data indicating a
            connection.
          </p>
          <p className="mb-8">
            <strong>Seller's page/vendor in TryBuy.club:</strong> Allows Users to have their own
            presentation page on TryBuy.club, containing their ads and additional content. The page
            is activated automatically upon publishing at least one ad and deactivated if the User
            cancels their account.
          </p>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            III. Rules regarding the creation of a user profile (account, registration):
          </h2>
          <p className="mb-4">
            <strong>
              2. For posting ads on the TryBuy.club internet platform and for using its other
              functionalities, registration is required, which is done by filling out an electronic
              form where the user should specify a username, password, email address, accept the
              current Terms, and consent to the processing of personal data. The user will receive
              an automatic message at the provided email address containing a link to confirm the
              email address, valid for 24 hours. If the confirmation link is not used within the
              specified period, the entered data for the respective registration is automatically
              deleted. If the link is visited, the registration associated with that link is
              activated.
            </strong>
          </p>
          <p className="mb-4">
            Some functionalities on the platform require verification of a real phone number.
            Verification is done through a phone call or SMS. If the user refuses to provide their
            real phone number, the corresponding functionality will be unavailable.
          </p>
          <p className="mb-4">
            In the created user profile, the user has the opportunity to fill in additional personal
            information such as names, phone number, address, website, etc., and also to add photos.
            By providing this information, the user declares that the provided data is voluntarily
            provided, complete, and accurate, and that they will update it promptly in case of any
            changes. Information on how the provided personal data is processed and for what
            purposes is contained in the Privacy Policy, an integral part of these Terms.
          </p>
          <p className="mb-4">
            The created account on the TryBuy.club internet platform is accessed using a username
            and password. The user is required to keep and not disclose their username and password
            for use by third parties and should promptly notify the Administrator in case of
            password disclosure.
          </p>
          <p className="mb-8">
            Regardless of whether it is an individual, legal entity, or group of persons engaged in
            common activities (see 1.6), the user is entitled to only one user profile on the
            TryBuy.club internet platform.
          </p>
          <div>
            <h2 className="text-xl lg:text-2xl font-semibold mb-4">
              IV. Rules regarding the publication and content of advertisements:
            </h2>
            <div>
              <strong>
                6. The Administrator reserves the right to delete without warning
                ads/comments/messages/account/advertising/photos/videos:
              </strong>
            </div>
            <div>
              <p>6.1. In which the mandatory fields are filled incorrectly.</p>
              <p>6.2. Without an accurate and detailed description of the property offered.</p>
              <p>
                6.3. That do not correspond or partially do not correspond to a specific category
                and subcategory of the internet platform.
              </p>
              <p>
                6.4. Ads analogous in meaning or content (text, photos, or video) to an already
                published ad (even in a different category, subcategory, location) by the same user
                or related persons according to 1.6.
              </p>
              <p>
                6.5. Ads containing text, photos, or video similar to ads published earlier by other
                users on the platform.
              </p>
              <p>6.6. Containing more than one property offered.</p>
              <p>6.7. Containing non-existent, inaccurate, incorrect, or false information.</p>
              <p>
                6.8. Containing photos or video that do not correspond to the specific offer
                contained in the ad text.
              </p>
              <p>
                6.9. Offering properties for which the publisher is not authorized by the owner or
                holder.
              </p>
              <p>
                6.10. That are not entered in English. The use of a foreign language is permissible
                if it represents an alternative translation of the English text entered in the ad.
              </p>
              <p>
                6.11. Containing text or individual words and parts of words in capital letters
                (Caps Lock). Example: SELLING AN APARTMENT.
              </p>
              <p>
                6.12. Containing multiple spelling errors, special characters, unnecessary spaces,
                periods, etc. punctuation marks, a set of identical or meaningless letters, as well
                as text that does not relate to a specific property.
              </p>
              <p>
                6.13. Containing intrusive inscriptions (e.g., Urgent; Attention; Sale, etc.),
                visual effects, add-ons, and processed photos that distinguish the ad from other
                ads, beyond the permissible methods of the platform.
              </p>
              <p>
                6.14. Containing any kind of contact information (phone number, email address,
                website, or other contact details, including social media) in the title or text of
                the ad (except for special fields) or in the attached photos or video in the ad,
                including in the profile picture (logo).
              </p>
              <p>
                6.15. With photos or video containing logos, advertisements, or identifiers of other
                advertising sites or content that refers with text, web address, or link to other
                advertising sites (except for special fields).
              </p>
              <p>
                6.16. Ads for real estate with the text Owner, provided that the ad publisher is not
                the real owner of the property.
              </p>
              <p>6.17. Ads offering properties that are not currently valid.</p>
              <p>
                6.18. In which after editing the ad, the initially published property has been
                replaced.
              </p>
              <p>6.19. Containing value-added phones.</p>
              <p>6.20. Containing non-original photos and videos.</p>
              <p>6.21. For which multiple complaints are received.</p>
              <p>6.22. That in any way violate copyright or other intellectual property rights.</p>
              <p>6.23. Containing illegal or immoral text content, photos, or video.</p>
              <p>6.24. Containing personal data (copy of passport, ID card, or other).</p>
              <p>6.25. Containing photos or videos with pornographic content.</p>
              <p>
                6.26. Offering properties for which a permit, license, or registration regime has
                been established, except in the cases provided for by law exceptions and if a photo
                of the document certifying the respective permission, license, or registration is
                attached to the ad.
              </p>
              <p>6.27. Containing text, photos, or video aimed at political agitation.</p>
              <p>
                6.28. Containing obscene expressions, insults, or threats, as well as those calling
                for the spread of violence, anti-democratic ideology, discrimination, or hatred on
                any basis.
              </p>
              <p>6.29. Ads aimed at collecting information about Users.</p>
              <p>
                6.30. Containing proposals for participation in financial or commodity pyramids or
                other similar structures or offering dubious ways of generating income, as well as
                related training.
              </p>
              <p>
                6.31. Containing text, photo, or video aimed at unfair competition, property, or
                emotional harm to other Users of the platform or third parties.
              </p>
              <p>6.32. Messages and comments for advertising purposes.</p>
              <p>6.33. With unrealistic price or price without VAT.</p>
            </div>
            <div>
              <p>
                7. Reposting of the same property by the same User in a new ad is not allowed if the
                property is present in a deleted, expired, or archived ad.
              </p>
              <p>8. Up to 6 media files can be attached to each ad.</p>
              <p>8.1. All media files must:</p>
              <ul>
                <li>Be owned by the publisher or for which he has a legal basis to use them;</li>
                <li>Be of good quality;</li>
                <li>
                  Correspond to the content of the ad and clearly and unambiguously show what is
                  being offered;
                </li>
                <li>
                  Not show people, properties, objects, or content for which the publisher does not
                  have explicit consent from the relevant persons/owners/right holders. The
                  publishing User should be able to prove at any time that he has explicit consent
                  or rights to publish the respective media files.
                </li>
                <li>
                  Meet additional requirements that are explicitly described in the photo/video
                  field when posting the ad.
                </li>
              </ul>
              <p>
                8.2. Supported media formats - Photos supported formats .jpg, .jpeg, .gif, .png up
                to 4 MB; Photos are resized to the limits of 1280x1000 pixels if their dimensions
                exceed them; A watermark trybuy.club is automatically added to each photo to prevent
                unauthorized copying of user content.
              </p>
            </div>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            V. Ad Management on trybuy.club Internet Platform
          </h2>
          <div>
            <strong>9. Paid Services:</strong>
          </div>
          <div>
            <p>
              9.1. Ads are published for a period chosen by the User, with the validity of the ad
              being extendable. Corrections to ads are unlimited and free of charge.
            </p>
            <p>
              9.2. Ads are automatically deactivated in the User's profile after their validity
              expires, unless renewed or extended by the User. The User can also deactivate their
              ads themselves before their validity expires.
            </p>
            <p>9.3. All ads by the User can be deleted by them at any time.</p>
            <p>
              9.4. The trybuy.club platform offers paid options for promoting an ad, described in
              section .......... These can be paid through the payment system integrated into the
              TryBuy.club platform.
            </p>
            <p>
              9.5. The price for a specific service from the Administrator may vary depending on its
              duration. The tariff for paid services is located at the following email address:
              contacts@trybuy.club The Administrator reserves the right to change the price of paid
              services at any time, as well as the right to change the ways in which they can be
              used.
            </p>
            <p>
              9.6. More information about paid services on the trybuy.club platform can be found at
              the following email address: contacts@trybuy.club
            </p>
            <p>
              9.7. The User cannot cancel a paid service and request a refund if its execution has
              already started by the Administrator.
            </p>
            <p>
              9.8. Using a paid service for an ad does not guarantee its publication if the ad does
              not comply with the current Terms, Bulgarian legislation, or applicable regulations.
              The Administrator has the right to remove such an ad, with no refund of the paid
              service.
            </p>
            <p>
              9.9. The Administrator does not refund amounts for prepaid subscriptions or paid ad
              services in the following cases:
              <ul>
                <li>
                  9.9.1. when the ad is removed by the Administrator after a signal from the User
                  that it is no longer valid;
                </li>
                <li>
                  9.9.2. in cases where the ad has been archived or deleted by the User themselves;
                </li>
                <li>
                  9.9.3. in all cases where the User's profile is blocked/deleted and/or the ads are
                  restricted/deleted by the Administrator due to actions by the User that violate
                  these Terms, legislation, or applicable regulations.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h2 className="text-xl lg:text-2xl font-semibold mb-4">
              VI. Rights and Obligations of the User
            </h2>
            <div>
              <strong>18. The User has the right to:</strong>
            </div>
            <div>
              <p>
                18.1. register one user profile on the trybuy.club platform and use the services,
                resources, and information available on the platform in accordance with these Terms.
                Information about the User's rights regarding the processing of their personal data
                is contained in the Privacy Policy section, an integral part of these Terms.
              </p>
              <p>18.2. The User's obligations include, but are not limited to:</p>
              <ul>
                <li>
                  18.2.1. not using unregulated advertising, marketing, and other tactics that are
                  beyond the scope of the services provided by the Administrator;
                </li>
                <li>
                  18.2.2. not sending or disseminating in any way advertisements, unsolicited
                  commercial messages and letters, with the aim of seeking and gaining customers,
                  through the electronic messaging exchange system of the platform. Upon receiving
                  such messages, the User must inform the Administrator immediately.
                </li>
                <li>
                  18.2.3. not disclosing personal data concerning other Users, which have become
                  known to the User when using the services of the Administrator, except in cases
                  provided for by law and the current Privacy Policy.
                </li>
                <li>
                  18.2.4. keeping their username and password for access to the trybuy.club platform
                  confidential and not providing their user profile for use by other persons;
                </li>
                <li>
                  18.2.5. not publishing ads, comments, messages, and other materials that violate
                  these Terms, legislation, or applicable regulations, as well as those that
                  infringe the rights of third parties.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2 className="text-xl lg:text-2xl font-semibold mb-4">
              VII. Rights and Obligations of the Administrator
            </h2>
            <div>
              <p>
                <strong>21. The Administrator is not obliged to:</strong>
              </p>
              <p>
                21.1. control the content of ads and advertisements published on the internet
                platform, or to monitor whether they violate these Terms, legislation, or applicable
                regulations, copyright or related rights, or rights over intellectual property, or
                to monitor the unlawful use of third-party trademarks, registered indications of
                origin, etc., nor to seek facts and circumstances indicating the commission of
                unlawful activities.
              </p>
              <p>
                22. The Administrator has the right at his discretion, without warning and without
                being responsible for these actions:
              </p>
              <ul>
                <li>
                  22.1. to delete profiles, ads, photos, videos, comments, messages that violate
                  these Terms, legislation, and applicable regulations, as well as those that
                  infringe the rights of third parties, with no refund of sums paid;
                </li>
                <li>
                  22.2. to lock or delete ads and registrations in case of frequent corrections to
                  the content, photos, and videos, as well as when replacing the content, photos, or
                  videos of the ad with those of another property, with no refund of sums paid;
                </li>
                <li>
                  22.3. to deactivate and return for correction ads that do not meet the
                  requirements of these Terms, with no refund of sums paid;
                </li>
                <li>
                  22.4. to change the category and subcategory of each ad that the Administrator
                  deems correct and suitable for the respective ad;
                </li>
                <li>
                  22.5. to postpone the publication of a User's ad for the purpose of conducting a
                  check for compliance of the content of the ad with these Terms, legislation, or
                  applicable acts;
                </li>
                <li>
                  22.6. to reject or stop ads whose content and/or design violate these Terms,
                  provisions of the current legislation, applicable acts, good morals, as well as
                  when they affect or could affect the rights, economic, and/or other legitimate
                  interests of the Administrator and/or third parties;
                </li>
                <li>
                  22.7. to block the User's access to their user profile in cases provided for in
                  these Terms.
                </li>
              </ul>
              <p>
                23. The Administrator has the right at his discretion to restrict access to part of
                the content of the internet platform to persons under 18 years of age.
              </p>
              <p>
                24. The Administrator may allow or refuse the creation of a new profile by a User
                whose data has been added to the Administrator's blacklist.
              </p>
              <p>
                25. The Administrator has the right at his discretion and in accordance with
                agreements with third parties to place advertising banners and other advertising
                materials anywhere on the internet platform, including in the space occupied by the
                ad, regardless of whether it is free or paid, and regardless of whether the banner
                or advertising material belongs to a competitor of the ad publisher.
              </p>
              <p>
                26. The trybuy.club platform may contain links or references to websites that are
                owned or operated by third parties. The Administrator is not obliged to control and
                monitor these links and references, nor is he responsible for the content, privacy
                policy, or security of the websites they lead to. If the User establishes a link
                with such websites, they do so entirely at their own risk and responsibility.
              </p>
              <p>
                27. The Administrator is entitled to compensation for all damages, expenses, and
                claims of third parties resulting from the User's violation of these Terms,
                applicable legislation, or applicable regulations and/or unauthorized use of the
                services provided by the internet platform.
              </p>
            </div>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">VIII. Liability</h2>
          <div>
            <p>
              <strong>28. TryBuy.club is not:</strong>
            </p>
            <p>
              28.1. liable for the content of ads and advertisements published on the internet
              platform, nor is it a party or intermediary in the relationships between Users,
              concerning or in connection with the published ads or advertisements, and does not
              exercise direct or indirect control over them. The sole responsibility for the content
              of the ad and advertisement lies with its issuer. Users use the published information
              at their own risk and responsibility. In this sense, the Administrator bears no
              responsibility towards the User and third parties, concerning or in connection with
              the published ads or advertisements, including in the following non-exhaustive
              hypotheses:
            </p>
            <ul>
              <li>
                28.1. The availability, safety, and quality of the properties presented to the User
                through published ads, electronic references, advertising banners, photos, videos,
                etc.;
              </li>
              <li>
                28.2. The emergence, guarantee, performance, modification, and termination of
                obligations and commitments undertaken in connection with properties offered by
                third parties through published ads, electronic references, advertising banners,
                etc., nor is it liable for the unlawful nature of the activities of third parties or
                for damages and losses suffered, resulting from these relationships;
              </li>
              <li>28.3. The accuracy and timeliness of the published ads and advertisements;</li>
              <li>
                28.4. Subjective perceptions and interpretations of the accuracy, completeness, and
                usefulness of the informational resources of the internet platform;
              </li>
              <li>
                28.5. Materials or information published by the User on the internet platform that
                in any way infringe upon the property or non-property rights of third parties,
                including copyright or intellectual property rights, as well as any other form of
                unlawful use of third-party trademarks, registered indications of origin, etc.;
              </li>
              <li>
                28.6. Damages suffered by the User as a result of unauthorized use of their username
                and password by third parties;
              </li>
              <li>
                28.7. Opinions/comments/texts expressed by users containing illegal, defamatory,
                offensive, derogatory, threatening, harmful, uncensored by their nature, etc.,
                content, or containing defamatory, racist, sexual, discriminatory, violent, or
                inciting to acts of violence, terror, anti-democratic ideologies, or other
                condemnatory words, expressions, appeals, statements, qualifications, etc.;
              </li>
              <li>
                28.8. Failure to provide the services of the internet platform in case of
                circumstances beyond its control; malicious attacks by third parties, in case of
                force majeure, accidental events, problems in the global Internet network and in the
                provision of services beyond the control of the Administrator;
              </li>
              <li>
                28.9. Damages suffered and losses incurred by the User as a result of the
                termination, suspension, modification, or restriction of the services of the
                internet platform, the deletion, modification, loss, unreliability, inaccuracy, or
                incompleteness of messages, ads, and advertisements made available through the
                trybuy.club platform;
              </li>
              <li>
                28.10. Damages suffered and losses incurred by the User as a result of the blocking
                of their user profile and/or deletion of their registration, in cases of identified
                violations, as well as providing information about the User in compliance with the
                orders of competent state authorities, requested in due form.
              </li>
            </ul>
            <p>
              29. The User bears full responsibility both to the Administrator and to third parties
              for the content of the ads and advertisements published by them, and in this regard
              for any errors or inaccuracies in the description of the property in the ad or
              advertisement, for the personal messages sent, comments, and signals, as well as for
              all their actions or actions performed through their user profile, in connection with
              the use of the trybuy.club platform, which violate these Terms, applicable legislation
              or applicable regulations, including cases of infringement of copyright or related
              rights, as well as rights over intellectual property, rights to trademarks and signs,
              as well as in cases of property or moral damages and inconveniences caused to third
              parties. The User is responsible for their actions even after closing their user
              profile.
            </p>
            <p>
              30. The User undertakes to indemnify the Administrator for all damages suffered and/or
              losses incurred by the latter, including paid pecuniary sanctions, paid compensation
              to third parties, legal fees, and/or other expenses, as a result of open proceedings
              or claims made against the Administrator in connection with ads, comments, messages,
              advertisements, photos, videos, etc., published by the User in violation of these
              Terms, Bulgarian legislation, applicable regulations of the European Union, or good
              morals, etc.
            </p>
            <p>
              31. The Administrator has the right at his discretion, without warning, to take
              measures against Users in case of identified repeated violations of these Terms,
              applicable legislation, or applicable regulations, as well as in case of multiple
              complaints:
            </p>
            <ul>
              <li>
                31.1. In case of serious or repeated violations by a User, the Administrator has the
                right to delete their user profile and add their contacts to a blacklist in order to
                prevent re-registration of the same in the trybuy.club internet platform.
              </li>
              <li>
                31.2. In case of serious or repeated violations by a User with a monthly
                subscription (prepaid package of ads exceeding the free limits):
                <ul>
                  <li>
                    For one violation within the current month; the Administrator has the right to
                    delete the ads published in violation of these Terms, applicable legislation, or
                    applicable regulations.
                  </li>
                  <li>
                    For a second violation within the current month; the Administrator has the right
                    to remove the advertising subscription and the related additional services, as
                    well as to deactivate all photos and videos in the User's ads for a period of 7
                    calendar days.
                  </li>
                  <li>
                    For three or more violations within the current month; the Administrator has the
                    right to restrict the User's access to the internet platform.
                  </li>
                </ul>
              </li>
              <li>
                31.3. At the discretion of the Administrator, other measures and restrictions may be
                imposed.
              </li>
            </ul>
            <p>
              32. Providing misleading or incorrect information to the trybuy.club team in the form
              of an e-mail, message, or report on a published ad in the internet platform is not
              allowed, and the sender of such information will be subject to measures in accordance
              with point 38.
            </p>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">IX. Termination of Services</h2>
          <div>
            <p>
              <strong>33. Ads may be deleted in the following cases:</strong>
            </p>
            <ul>
              <li>
                33.1. At any time by the User through the functionality on the internet platform;
              </li>
              <li>
                33.2. After the expiration of the ads or after their manual deactivation by the
                User;
              </li>
              <li>33.3. By the Administrator, in the cases provided for in these Terms.</li>
            </ul>
            <p>
              <strong>34. User accounts may be blocked in the following cases:</strong>
            </p>
            <ul>
              <li>
                34.1. When there are suspicions of the User violating these Terms, other conditions
                provided in the internet platform, applicable legislation, or applicable
                regulations, the rights, freedoms, and legitimate interests of the Administrator or
                third parties;
              </li>
              <li>34.2. Upon request from a competent state authority;</li>
              <li>34.3. By request from the User, if there is a valid reason for it.</li>
            </ul>
            <p>
              <strong>35. User accounts may be deleted in the following cases:</strong>
            </p>
            <ul>
              <li>
                35.1. At any time, upon request from the User, through the functionality on the
                internet platform;
              </li>
              <li>
                35.2. Automatically, after a long period of account inactivity (more than 1 year);
              </li>
              <li>
                35.3. If there are two or more profiles of one person, the profiles are reduced to
                one at the discretion of the Administrator;
              </li>
              <li>
                35.4. At the discretion of the Administrator, profiles created to circumvent the
                restrictions established by these Terms are deleted;
              </li>
              <li>
                35.5. For violation of these Terms, other conditions provided in the internet
                platform, applicable legislation, or applicable regulations;
              </li>
              <li>
                35.6. When the User's actions affect or could affect the rights, economic and/or
                other legitimate interests of the Administrator and/or third parties;
              </li>
              <li>
                35.7. As a preventive measure against abuses, when the User uses
                VPN/TOR/Proxy/public or other networks for anonymization purposes;
              </li>
              <li>
                35.8. In other cases at the discretion of the Administrator, with prior notice.
              </li>
            </ul>
            <p>
              <strong>
                36. Upon deletion of the account, all accompanying profile data are erased - ads,
                contact information, etc. Deleted ads and accounts are not restored. Information on
                how the provided personal data is deleted is contained in the Personal Data
                Protection Policy section, an integral part of these Terms.
              </strong>
            </p>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">
            X. Copyrights and Intellectual Property Rights
          </h2>
          <div>
            <p>
              All copyrights and intellectual property rights to the informational resources and
              materials published on the internet platform are subject to protection under the
              current legislation, and their unauthorized use constitutes a violation of the law and
              entails civil, administrative, and criminal liability. The User confirms and
              guarantees that they own or have the necessary permissions (licenses, rights, consent)
              from the rights holders, in connection with the content of the ads or advertisements
              published by them on the trybuy.club platform, giving their explicit consent for the
              Administrator to use, reproduce, distribute, create derivative works in connection
              with the provision of their Services, including, but not limited to, for their
              popularization in all media formats and channels.
            </p>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">XI. Changes</h2>
          <div>
            <p>
              The Administrator reserves the right to unilaterally and at any time amend these
              Terms, its Personal Data Protection Policy, and Cookie Policy by timely publishing
              these changes on the trybuy.club platform. They shall enter into force on the day of
              their publication on trybuy.club.
            </p>
            <p>
              The User is obliged to check the currentness of the Terms at any time before using the
              internet platform. If the User continues to use the services of the Administrator
              after updating the Terms and the Personal Data Protection Policy, it shall be deemed
              that they have read, accepted, and agreed to the changes.
            </p>
            <p>
              If the User does not agree with the changes in the Terms or the Personal Data
              Protection Policy, they should discontinue using the services of the Administrator and
              delete their user profile.
            </p>
            <p>For all unresolved issues with these Terms, the current legislation shall apply.</p>
          </div>
          <h2 className="text-xl lg:text-2xl font-semibold mb-4">XII. Contact Information</h2>
          <div>
            <p>
              <strong>Email: contacts@trybuy.club</strong>
            </p>
          </div>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
