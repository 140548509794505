import axios from "axios";

export default async function payAdPackage(
  id: string | number,
  packageId: string,
  offerId: number
) {
  try {
    const response = await axios.post(
      `/advertisment/pay/${id}`,
      { packageId, offerId },
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    const success = response.data;
    if (!success) {
      return null;
    }
    return success;
  } catch (error: any) {
    throw new Error(error);
  }
}
