import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import { Listing } from "Types/Listing";
import { formatPrice } from "pages/ListingStayDetailPage";
import { adItems } from "components/Modals/PropertyPostModal";

export interface PropertyCardHProps {
  className?: string;
  data: Listing;
  isLoaded?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardH: FC<PropertyCardHProps> = ({ className = "", data, isLoaded }) => {
  const {
    galleryImgs,
    guestCount,
    roomCount,
    bathroomCount,
    bedCount,
    listingCat,
    address,
    categorySlug,
    name,
    size,
    price,
    city,
    pricePerNight,
    id,
    sizeUnit,
    priceUnit,
    packageId,
  } = data;

  const adItem = adItems.find((item) => item.id === packageId);
  const renderSliderGallery = () => {
    return !isLoaded ? (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 animate-pulse border-black border-5">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform invisible"
          uniqueID={`PropertyCardH_${id}`}
          href={`/listings/${id}`}
        />
      </div>
    ) : (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 animate-appear ">
        <GallerySlider
          ratioClass="aspect-w-1 aspect-h-1"
          galleryImgs={galleryImgs}
          className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
          uniqueID={`PropertyCardH_${id}`}
          href={`/listings/${id}`}
        />
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        {isLoaded ? (
          <div className="flex items-center space-x-2 animate-appear">
            <span className="hidden sm:inline-block">
              <i className="las la-bed text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">{bedCount} beds</span>
          </div>
        ) : (
          <div className="flex items-center space-x-2 animate-pulse rounded-lg">
            <span className="hidden sm:inline-block invisible">
              <i className="las la-bed text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400 invisible">
              {bedCount} beds
            </span>
          </div>
        )}

        {/* ---- */}
        {isLoaded ? (
          <div className="flex items-center space-x-2 animate-appear">
            <span className="hidden sm:inline-block">
              <i className="las la-bath text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {bathroomCount} baths
            </span>
          </div>
        ) : (
          <div className="flex items-center space-x-2 animate-pulse rounded-lg">
            <span className="hidden sm:inline-block invisible">
              <i className="las la-bath text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400 invisible">
              {bathroomCount} baths
            </span>
          </div>
        )}

        {/* ---- */}
        {isLoaded ? (
          <div className="flex items-center space-x-2 animate-appear ">
            <span className="hidden sm:inline-block">
              <i className="las la-expand-arrows-alt text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400">
              {size} {sizeUnit}
            </span>
          </div>
        ) : (
          <div className="flex items-center space-x-2 animate-pulse rounded-lg">
            <span className="hidden sm:inline-block invisible">
              <i className="las la-expand-arrows-alt text-lg"></i>
            </span>
            <span className="text-xs text-neutral-500 dark:text-neutral-400 invisible">
              {size} {sizeUnit}
            </span>
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    const parsedCity = city as unknown as string;

    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="text-sm text-neutral-500 dark:text-neutral-400">
          <span>
            {address}, {parsedCity}
          </span>
        </div>
        <div className="space-y-4 w-full">
          {isLoaded ? (
            <div className="flex items-center space-x-2 animate-appear">
              {adItem?.id !== "free" && <Badge name={adItem?.name} color={adItem?.badgeColor} />}
              <h2 className="text-lg font-medium capitalize">
                <span className="line-clamp-2">{name}</span>
              </h2>
            </div>
          ) : (
            <div className="flex items-center space-x-2 animate-pulse rounded-lg">
              <div className="text-sm text-neutral-500 dark:text-neutral-400">
                <span>
                  {listingCat} in {address}, {"asd"}
                </span>
              </div>
              {true && <Badge name="ADS" className="invisible" color="green" />}
              <h2 className="text-lg font-medium capitalize invisible">
                <span className="line-clamp-2">{name}</span>
              </h2>
            </div>
          )}
          {renderTienIch()}
          <div className="w-32 border-b border-neutral-200 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            {isLoaded ? (
              <div>
                <span className="flex items-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500 animate-appear mb-2">
                  {`${formatPrice(pricePerNight)} ${priceUnit == "USD" ? "$" : "€"} / per night `}
                </span>

                <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500 animate-appear mb-2">
                  {`${formatPrice(price)} ${priceUnit == "USD" ? "$" : "€"} / buying price`}
                </span>
              </div>
            ) : (
              <span className="flex items-center justify-center px-3 py-2 rounded leading-none text-base font-medium text-secondary-500 animate-pulse">
                <span className="invisible">{`${formatPrice(price)} ${
                  priceUnit == "USD" ? "$" : "€"
                }`}</span>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className} `}
      data-nc-id="PropertyCardH"
    >
      <Link to={`/listings/${id}`} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {/* <BtnLikeIcon
        colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
        isLiked={like}
        className="absolute right-5 top-5 sm:right-3 sm:top-3 "
      /> */}
    </div>
  );
};

export default PropertyCardH;
