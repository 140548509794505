import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { Reservation } from "./listing-stay-detail/StayDatesRangeInput";
import { eachDayOfInterval } from "date-fns";
import { formatDate } from "components/ModalSelectDate";
import { useNavigate, useSearchParams } from "react-router-dom";
interface SectionDateRangeProps {
  reservations: Reservation[];
  changedDatesAvCalendar: (checkIn: Date | null, checkOut: Date | null) => void;
}

const SectionDateRange: React.FC<SectionDateRangeProps> = ({
  reservations,
  changedDatesAvCalendar,
}) => {
  const [searchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(searchParams.get("checkIn") || Date.now())
  );
  const [endDate, setEndDate] = useState<Date | null>(
    new Date(searchParams.get("checkOut") || Date.now())
  );
  const router = useNavigate();
  const disabledDates = useMemo(() => {
    let dates: Date[] = [];
    reservations.forEach((reservation) => {
      const range = eachDayOfInterval({
        start: new Date(reservation.startDate),
        end: new Date(reservation.endDate),
      });
      dates = [...dates, ...range];
    });
    return dates;
  }, [reservations]);
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    changedDatesAvCalendar(start, end);
  };
  const updateURL = async () => {
    const currentQuery = Object.fromEntries(searchParams);
    const updatedQuery = {
      ...currentQuery,
      checkIn: formatDate(new Date(startDate || Date.now())) || "",
      checkOut: formatDate(new Date(endDate || Date.now())) || "",
    };

    const paramsString = new URLSearchParams(updatedQuery).toString();
    const url = paramsString ? `?${paramsString}` : "/";
    router(url);
  };
  useEffect(() => {
    updateURL();
  }, [startDate, endDate]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderSectionCheckIndate = () => {
    return (
      <div className="listingSection__wrap overflow-hidden">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Availability</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}

        <div className="">
          <DatePicker
            selected={startDate}
            onChange={onChangeDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            excludeDates={disabledDates}
            monthsShown={isMobile ? 1 : 2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
            renderDayContents={(day, date: Date) => (
              <DatePickerCustomDay
                dayOfMonth={day}
                date={date}
                isExcluded={disabledDates.some(
                  (disabledDate) => disabledDate.getTime() === date.getTime()
                )}
              />
            )}
          />
        </div>
      </div>
    );
  };

  return renderSectionCheckIndate();
};

export default SectionDateRange;
