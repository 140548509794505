import { AdItem } from "components/Modals/PropertyPostModal";
import create from "zustand";

interface AdPackagePayModalState {
  isOpen: boolean;
  adItem: AdItem;
  offerId: number;
  listingId: number;
}

interface AdPackagePayModalActions {
  onOpen: () => void;
  onClose: () => void;
  setAdItem: (adItem: AdItem) => void;
  setOfferId: (offerId: number) => void;
  setListingId: (listingId: number) => void;
}

const useAdPackagePayModal = create<AdPackagePayModalState & AdPackagePayModalActions>((set) => ({
  isOpen: false,
  offerId: 0,
  listingId: 0,
  adItem: {
    id: "free",
    name: "Free (No advertisment)",
    bonusDaysFirstListing: 0,
    badgeColor: "green",
    data: [
      {
        id: 1,
        days: 0,
        price: 0.0,
      },
    ],
    perks: [""],
  },
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
  setAdItem: (adItem: AdItem) => set({ adItem }),
  setOfferId: (offerId: number) => set({ offerId }),
  setListingId: (listingId: number) => set({ listingId }),
}));

export default useAdPackagePayModal;
