import React, { useCallback, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import Modal from "./Modal";
import axios from "axios";
import useRegisterModal from "../../hooks/useRegisterModal";
import Heading from "../Heading";
import toast from "react-hot-toast";
import useLoginModal from "../../hooks/useLoginModal";
import Input from "components/Inputs/Input";
import useAccountMobileModal from "hooks/useAccountMobileModal";
import ModalButton from "components/ModalButton";
import { useNavigate } from "react-router-dom";
import useAdModal from "hooks/useAdModal";
import PackageInformationModal from "./PackageInformationModal";
import { FaCircleInfo } from "react-icons/fa6";
import InfoModal from "./InformationModal";
import { adItems } from "./PropertyPostModal";
import useAdPackagePayModal from "hooks/useAdPackagePayModal";
const AdPackagesModal: React.FC = () => {
  const navgiate = useNavigate();
  const adModal = useAdModal();
  const adPayModal = useAdPackagePayModal();
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [advertismentInfoOpen, setAdvertismentInfoOpen] = useState<boolean>(false);
  const [offerInfoModalOpen, setOfferInfoModalOpen] = useState<boolean>(false);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [selectedOffer, setSelectedOffer] = useState<{ [key: string]: number }>({});
  const [currentlySelectedPackage, setCurrentlySelectedPackage] = useState<string>(
    `Currently selected: Package ${adModal.adItem.name}`
  );
  const handlePurchase = (itemId: string, offerId: number) => {
    adPayModal.setListingId(adModal.listingId);
    adPayModal.setAdItem(
      adItems.find((item) => item.id === itemId) || {
        id: "free",
        name: "Free (No advertisment)",
        bonusDaysFirstListing: 0,
        badgeColor: "green",
        data: [
          {
            id: 1,
            days: 0,
            price: 0.0,
          },
        ],
        perks: [""],
      }
    );
    adPayModal.setOfferId(offerId);
    adPayModal.onOpen();
    adModal.onClose();
    // setCustomValue("packageId", itemId);
    // setCustomValue("offerId", offerId);
  };
  const handleClick = (value: string) => {
    setActiveItem(activeItem === value ? null : value);
  };
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
    const selectedOfferId = parseInt(e.target.value);
    setSelectedOffer((prevState) => ({
      [itemId]: selectedOfferId,
    }));
  };

  const bodyContent = (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Heading title="Advertisment packages!" subtitle="Which package suits your needs best?" />
        <span className="flex gap-2 items-center">
          How it works
          <FaCircleInfo
            size={20}
            onClick={() => {
              setAdvertismentInfoOpen(true);
            }}
            className="info-icon text-blue-500 cursor-pointer flex justify-end group-hover:opacity-100"
          />
          {advertismentInfoOpen && (
            <InfoModal
              onClick={() => {
                setAdvertismentInfoOpen(false);
              }}
              text={`Please choose the package that best suits your needs, and once your listing has been approved, you can proceed with payment (if its any of the paid packages) for the chosen package.`}
            />
          )}
        </span>
      </div>
      <span className="text-sm font-bold">{currentlySelectedPackage}</span>
      {adItems.map((item, i) => (
        <div key={item.id} className="mb-4 cursor-pointer">
          <button
            className="flex cursor-pointer justify-between w-full bg-white disabled:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-50 rounded-lg p-4 shadow-md hover:shadow-lg focus:outline-none focus:ring focus:ring-blue-300"
            onClick={() => handleClick(item.id)}
            disabled={adItems.findIndex((pack) => pack.id === adModal.adItem.id) >= i && i >= 1}
          >
            <span className="text-lg">{item.name}</span>
            <span>{activeItem === item.id ? "-" : "+"}</span>
          </button>
          {activeItem === item.id && (
            <div>
              <div className="bg-white rounded-lg p-4 shadow-md mt-5 animate-appear flex justify-between">
                <div className="flex flex-col">
                  {item.id !== "free" && (
                    <div className="flex gap-2 mb-2">
                      What is a "{item.name}" listing{" "}
                      <FaCircleInfo
                        size={20}
                        onClick={() => {
                          setOfferInfoModalOpen(true);
                        }}
                        className="info-icon text-blue-500 cursor-pointer"
                      />
                    </div>
                  )}
                  {item.data.map((offer) => (
                    <div key={offer.id} className="flex justify-between items-center mb-2">
                      <form className="flex items-center">
                        <label
                          key={offer.id}
                          className="flex justify-between items-center mb-2 cursor-pointer"
                        >
                          <input
                            type="radio"
                            className="form-radio h-5 w-5 text-[#06b6d4] outline-none focus:outline-none biorde" // Add focus:outline-none
                            name={`offer_${item.id}`}
                            value={offer.id.toString()}
                            onChange={(e) => handleRadioChange(e, item.id)}
                            checked={selectedOffer[item.id] === offer.id}
                          />
                          <div className="flex w-full items-between">
                            {item.data[0].days > 0 && (
                              <span className="ml-2">{offer.days} days</span>
                            )}
                            <span className="ml-2 justify-end">
                              ${offer.price.toFixed(2)}{" "}
                              {item.data[0].days > 0 &&
                                `($${(offer.price / offer.days).toFixed(2)} /day)`}
                            </span>
                          </div>
                        </label>
                      </form>
                    </div>
                  ))}
                  {item.id !== "free" ? (
                    <div className="flex gap-2 items-center">
                      <span className="font-bold">Bonus: </span>{" "}
                      <span className="">
                        First {item.name.toUpperCase()} listing every {item.bonusDaysFirstListing}{" "}
                        days
                      </span>
                      <FaCircleInfo
                        size={20}
                        onClick={() => {
                          setInfoModalOpen((value) => !value);
                        }}
                        className="info-icon text-blue-500 cursor-pointer flex justify-end group-hover:opacity-100"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {infoModalOpen && (
                    <InfoModal
                      onClick={() => {
                        setInfoModalOpen(false);
                      }}
                      text={`Every ${item.bonusDaysFirstListing} days the listing automatically becomes a "First ${item.name}"
                        if the listing is still ${item.name}. This happens at no cost.`}
                    />
                  )}
                  <button
                    onClick={() => {
                      handlePurchase(item.id, Number(selectedOffer[item.id]) - 1);
                    }}
                    disabled={!selectedOffer[item.id]}
                    className="bg-[#06b6d4] hover:scale-105 text-white px-4 py-2 rounded-lg mt-3 disabled:bg-gray-500 disabled:cursor-not-allowed"
                  >
                    {item.id === "free" ? "Downgrade (Free)" : "Purchase"}
                  </button>
                </div>

                {offerInfoModalOpen && (
                  <PackageInformationModal
                    onClick={() => {
                      setOfferInfoModalOpen(false);
                    }}
                    typeOfPackage={item.name}
                    paragraphs={item.perks}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const footerContent = (
    <div className="flex flex-col gap-4 mt-3 ">
      <hr />
    </div>
  );

  return (
    <Modal
      disabled={false}
      isOpen={adModal.isOpen}
      title="Advertisment"
      onClose={adModal.onClose}
      body={bodyContent}
      footer={footerContent}
    />
  );
};

export default AdPackagesModal;
