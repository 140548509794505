import React, { FC, useEffect, useState } from "react";
import L, { LatLng, LatLngTuple } from "leaflet";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetina: markerIcon2x,
  shadowUrl: markerShadow,
});

// Define custom marker icon
const customIcon = L.icon({
  iconUrl: "/../assets/images/home-marker.png",
  iconSize: [40, 40],
  iconAnchor: [16, 32],
  popupAnchor: [-3, -76],
});

interface MapProps {
  center?: LatLngTuple;
  zoom?: number;
  scrollWheelZoom?: boolean;
  onLocationChange?: (location: LatLngTuple, town: LatLngTuple) => void;
  onCountryChange?: (country: string) => void;
  onTownChange?: (town: string) => void;
}

const Map: FC<MapProps> = ({ center, onLocationChange, onCountryChange, onTownChange }) => {
  const initialPosition: LatLngTuple = center || [1, 1];
  const [markerPosition, setMarkerPosition] = useState<LatLngTuple>(initialPosition);

  const fetchCountryAndTown = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`
      );
      const data = await response.json();
      const country = data.address.country;
      if (onCountryChange) {
        onCountryChange(country);
      }
    } catch (error) {
      console.error("Error fetching country and town:", error);
    }
  };

  const handleMapClick = (e: L.LeafletMouseEvent) => {
    const { lat, lng } = e.latlng;
    const newPosition: LatLngTuple = [lat, lng];
    setMarkerPosition(newPosition);
    fetchCountryAndTown(lat, lng);
    if (onLocationChange) {
      onLocationChange(newPosition, [lat, lng]);
    }
  };

  const handleMarkerDragEnd = (e: L.DragEndEvent) => {
    const newPosition: LatLngTuple = e.target.getLatLng();
    setMarkerPosition(newPosition);
    fetchCountryAndTown(newPosition[0], newPosition[1]);
    if (onLocationChange) {
      onLocationChange(newPosition, [newPosition[0], newPosition[1]]);
    }
  };

  const MyComponent = () => {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  };

  useEffect(() => {
    fetchCountryAndTown(markerPosition[0], markerPosition[1]);
  }, []); // Fetch country and town when the component mounts

  return (
    <MapContainer
      center={initialPosition}
      zoom={10}
      scrollWheelZoom={true}
      className="h-[35vh] rounded-lg z-40"
    >
      <MyComponent />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {/* Use custom icon for the marker */}
      <Marker
        position={markerPosition}
        draggable={true}
        eventHandlers={{ dragend: handleMarkerDragEnd }}
        icon={customIcon}
      />
    </MapContainer>
  );
};

export default Map;
