import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSellerGuidesProps {
  className?: string;
}

const PageSellerGuides: FC<PageSellerGuidesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSellerGuides overflow-hidden relative ${className}`}
      data-nc-id="PageSellerGuides"
    >
      <Helmet>
        <title>Seller Guides - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            When is the Best Time to Sell a House?
          </h2>

          <p>
            Within the property market, there is much written about when the best time to buy and
            sell a house is. Just like trading other investments you can try to play the market to
            your advantage and this certainly applies with investment buyers, but when it comes to
            selling a home, it is often more about the right time for you rather than trying to
            judge the wider market.
          </p>

          <p>
            There are, however, peaks and troughs during the year and you might be wondering when is
            the most popular time to sell a house. Being aware of the trends could help you decide
            when to sell your home, but don’t forget, this doesn’t take into account your own
            reasons for wanting to sell.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">The New Year</h3>
          <p>
            People see the New Year as the best time for a fresh start following on from the
            Christmas season, making this a popular time to start the search for a new home.
            Activity in the buyers’ market picks up in the New Year and grows into the peak of
            spring. Those people looking early in the new year are generally at the start of their
            property search and may take several months before they have viewed enough homes to
            start making decisions.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Spring</h3>
          <p>
            Is the most popular time to buy and sell. It means that you can be in your new home by
            the summer and the enthusiasm from the New Year is still in the air. The Easter Bank
            holidays act as an inflexion point, giving people more time to view, to consider their
            options and to make decisions.
          </p>

          <p>
            Properties will start to view better as daylight gets longer and gardens start to come
            back to life. Finding a property in spring means that you will most likely be able to
            get into your new home by the summer.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Summer</h3>
          <p>
            There is often a slowdown of activity in the market during the summer as people focus on
            summer holidays, the school holidays and enjoying the longer evenings. In the knowledge
            it may take several months to complete a sale, families will look earlier in the year to
            ensure they are settled in their new home before starting new schools. The TryBuy.club
            platform can be very efficient during this period as it can combine the holidays season
            with the property search.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Autumn</h3>
          <p>
            Sees an increase in activity through to early November. People are back from their
            holidays, there is still the opportunity to get into a new home in time for Christmas
            and children are back at school giving more time to work through options. Those leaving
            education tend to start jobs in early autumn and this cycle carries through to people in
            more established jobs.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Winter</h3>
          <p>
            Unsurprisingly, this is a poorer time to try to sell. Buyers are pre-occupied with
            Christmas preparations; the nights have closed in and the properties tend to view less
            well in the winter light. The motivation of buyers is decreasing, except for those
            looking to try and find bargains by completing a purchase in time for Christmas.
          </p>

          <p>
            Aside from the market conditions, there is also the timing around what you are trying to
            achieve. Generally, if you are selling you are likely to be buying at the same time.
            Keeping a keen eye on the market in advance will help you align both your sale and your
            purchase. Practically speaking, as soon as you start marketing your property, you should
            be out looking for your next purchase.
          </p>
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            2. Advantages of using TryBuy.club
          </h2>
          <p>
            It’s not necessary to use an agent to sell your property, you can do it privately,
            although most sellers choose to in order to ensure they attract the best range of
            potential buyers. You can find agents all over the Wold in our Agents HUB - visit
            TryByu.club for more information.
          </p>

          <p>
            You don’t have to use an agent to sell your property, you can start with our platform
            TryBuy.club.
          </p>
          <p>
            At TryBuy.club, we revolutionize the way properties are discovered, experienced, and
            purchased. Our platform offers a unique proposition: Try Before You Buy. Imagine
            stepping into your potential future home, feeling its ambiance, and envisioning your
            life within its walls before making the big decision.
          </p>
          <p>TryBuy.club is your exclusive portal for this innovative service.</p>
          <p>
            It offers a practical and sensible approach to property transactions, allowing buyers to
            experience their potential future homes firsthand and sellers to showcase their listings
            effectively while earning rental income.
          </p>
          <p>
            By embracing Try Before You Buy, sellers gain a competitive edge in the market, enticing
            the most discerning buyers with the promise of a fully immersive property experience.
          </p>
          <p>
            Experience the future of property transactions with TryBuy.club – where exploration
            meets investment.
          </p>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            3. Preparing your property
          </h2>
          <p>
            Once you have decided to put your property on the market and you need to make sure you
            are ready for the marketing process.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">First Impressions</h3>
          <p>
            You often get two opportunities to make a first impression. Firstly online and secondly
            in real life:
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">Online:</h4>
          <p>
            This in part is about your choice of marketing the property online - websites and
            portals like TryBuy.club. It is also about being prepared for taking photographs and
            marketing details before the property goes live. Having images that show your property
            at its best will generate initial interest. It is important that you are telling a
            consistent story about your home and the photos should also match how the property
            presents itself in real life, so your potential buyers are not disappointed. Images
            should also be truthful and careful photography that conceals less desirable features
            won’t help you sell the property, as the potential buyer will see what’s there when they
            view.
          </p>

          <h4 className="text-xl lg:text-2xl font-semibold text-gray-900">In real life:</h4>
          <p>
            The way your property is presented from the outside, call it curb-appeal, will set up
            the way a potential buyer feels about the property before they come in when they first
            view it. Many buyers will do a ‘drive by’ in advance to get a first look at the outside
            of the property beforehand and so maintaining a proactive approach to keeping things
            neat and tidy will make all the difference, even before the visiting.
          </p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Presentation</h3>
          <p>
            The most successful viewing is when the potential buyer can see themselves living in the
            property and can also relate to the property as it is now. Some simple ways to improve
            the presentation of your property are:
          </p>

          <ul className="list-disc list-inside">
            <li>
              Give rooms the right purpose: We don’t always use rooms for the purpose they were
              intended. We may use a dining room as a bedroom or a bedroom as a study. Some buyers
              find it hard to see beyond the current use of a room so, if you are marketing a room
              as a bedroom, it is best to set it up as a bedroom so you are showing them the purpose
              of the room, not just telling them what it could be.
            </li>
            <li>
              Keep it tidy: Keeping your house clean and tidy will not only create the right
              impression but help buyers see beyond your contents.
            </li>
            <li>
              Let in light: Keeping curtains and blinds open and windows clean, will let in light
              and create a better sense of space.
            </li>
            <li>
              Set the temperature: If there are rooms in your home you rarely use and therefore
              rarely heat, switch on the radiators ahead of your viewing so the whole property is
              warm and welcoming.
            </li>
            <li>
              Bathrooms and kitchens: Should always be clean, tidy and mould free. In the kitchen
              keep the worktops as clear as possible, which will improve the sense of space.
            </li>
            <li>
              Keep it simple: If you feel the need to re-decorate before marketing your property
              choose neutral colours that have a universal appeal.
            </li>
            <li>
              Minor repairs: The little things add up and if there are simple repairs that you have
              been planning to do, get them done before putting your property on the market, it will
              give potential buyers few things to find wrong.
            </li>
          </ul>
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            1. How your property looks online
          </h2>
          <p>
            The online profile of your property will be one of the main sources of interest. A
            successful property listing should:
          </p>
          <ul className="list-disc list-inside">
            <li>Have at least 6 or 7 photographs including an external shot of the property</li>
            <li>
              Include photographs taken on a sunny day, ideally by a professional photographer.
            </li>
            <li>
              Avoid images that are very specific to a time of year, for example - Christmas. A
              picture of your Christmas tree in Spring is not going to help sell your property.
            </li>
            <li>
              Don’t include images that just show a piece of furniture, as they could be from any
              property
            </li>
            <li>Include a comprehensive description including the dimensions of each room</li>
            <li>Include a floorplan showing the scale and layout of the property</li>
            <li>Avoid including images of your personal photographs on the mantelpiece</li>
          </ul>
          <p>
            The more information, the more likely to be successful. As part of the client valuation
            process they will ask about the property, the neighbourhood and by gathering the
            information in advance it will help the process. You will also need to provide a lot of
            this information to potential buyers.
          </p>
          <p>Key items to gather:</p>
          <ul className="list-disc list-inside">
            <li>Property outgoings and how much you pay for:</li>
            <ul className="list-disc list-inside">
              <li>Council tax</li>
              <li>Energy bills</li>
              <li>Water charges</li>
              <li>Buildings and contents cover insurance</li>
            </ul>
            <li>If the property is Leasehold:</li>
            <ul className="list-disc list-inside">
              <li>How much are the annual service charges?</li>
              <li>How frequently are they paid?</li>
              <li>Is the managing agent good?</li>
              <li>How long is left on the property lease</li>
              <li>Are there any major works due in the next few years?</li>
            </ul>
          </ul>
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            2. Handling Viewings and Visits
          </h2>
          <p>
            As your property goes on the market, make sure your property is prepared. If you are
            going to conduct the viewings and meet the clients yourself, here are a few suggestions
            that will help it go smoothly:
          </p>
          <ul className="list-disc list-inside">
            <li>
              Be ready for them: If you are not prepared, it could create the impression that you
              aren’t really interested in selling the property.
            </li>
            <li>
              Be properly dressed: You don’t need a suit and tie but look like you were expecting
              them, if you look well dressed, it’s likely they will assume the same about your
              property.
            </li>
            <li>
              Smile: The process can be intimidating for both buyers and the seller, so a smile will
              break the ice at the beginning.
            </li>
            <li>
              Don’t talk too much: When we’re nervous we can often keep talking just to fill the
              silence and this can be off putting. If you are worried about saying too much try
              asking the buyer questions instead.
            </li>
            <li>
              Don’t be personal: Sometimes we move during stressful or emotional situations, so
              always avoid being too personal when talking about your property.
            </li>
            <li>
              If you have an agent refer to the agent: If you have instructed an agent to manage the
              process, don’t give the viewer your contact details for them to call you with any
              questions.
            </li>
            <li>
              It’s not personal: One of the most important things an agent can do is to take the
              emotion out, especially if the potential buyer is not as keen on your property as you
              are.
            </li>
          </ul>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            3. Ensuring the Sale Goes Smoothly
          </h2>
          <p>
            At the same time as marketing the property, we recommend that you organise the sale
            process in advance to do the legal work for you so that you are ready to start as soon
            as you have a client. It depends on the country laws what the process is and it is a
            good idea to have all the necessary documents and formalities ready to be completed as
            quickly as possible.
          </p>

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            4. Preparing to Move Out
          </h2>
          <p>The move will take a lot of preparation.</p>
          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">The Week Before</h3>
          <ul className="list-disc list-inside">
            <li>Confirm the date and time of moving day with your removal company.</li>
            <li>
              Pay your local bills such as newspaper delivery, milk, outstanding video rental, etc.
              where applicable and ensure future deliveries are cancelled.
            </li>
            <li>
              Prepare a note for the new owners explaining how things work and where they can find
              useful items such as the boiler switches, aerial sockets and alarm codes.
            </li>
            <li>
              Also include details of who currently provides the utilities at your property as it is
              not always obvious.
            </li>
          </ul>
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            2. Handling Viewings and Visits
          </h2>
          {/* Handling Viewings and Visits content */}

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            3. Ensuring the Sale Goes Smoothly
          </h2>
          {/* Ensuring the Sale Goes Smoothly content */}

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            4. Preparing to Move Out
          </h2>
          {/* Preparing to Move Out content */}

          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">Moving Day</h2>
          <p>Meet the removers and give them a quick tour of the existing property.</p>
          <p>Swap mobile telephone contact numbers with the removers.</p>
          <p>Make sure everyone else who is helping you move has each other’s’ numbers too.</p>
          <p>Check the removers know where they are going and have directions.</p>
          <p>Explain what is to go and what is to stay.</p>
          <p>
            If there is anything that requires extra care, point this out at the start, but avoid
            checking everything the removers take out – it is patronizing, unnecessary and causes
            delays.
          </p>
          <p>Do a final check when the van is loaded to ensure everything has been taken.</p>
          <p>Check all windows and doors are locked and the utilities are all turned off.</p>

          <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">Tips for Moving Day</h3>
          <ul className="list-disc list-inside">
            <li>
              If possible, try to stagger moving days so you don’t have to do everything all in one
              day.
            </li>
            <li>Try to avoid Mondays and Fridays which are the busiest days on the roads.</li>
            <li>Save time and cost by doing some of the packing yourself.</li>
            <li>
              Redirect post well beforehand – this is especially essential to protect yourself from
              identity theft.
            </li>
          </ul>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSellerGuides_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageSellerGuides;
