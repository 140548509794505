import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageSecurityTipsProps {
  className?: string;
}

const PageSecurityTips: FC<PageSecurityTipsProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageSecurityTips overflow-hidden relative ${className}`}
      data-nc-id="PageSecurityTips"
    >
      <Helmet>
        <title>Security Tips - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">
            TryBuy.club Safety and Security Centre
          </h2>
          <p className="text-lg lg:text-xl mb-4">
            At TryBuy.club, we revolutionize the way properties are discovered, experienced, and
            purchased. Our platform offers a unique proposition: Try Before You Buy. Imagine
            stepping into your potential future home, feeling its ambiance, and envisioning your
            life within its walls before making the big decision. We are advertising our customers'
            properties for sale and to rent. They are estate and letting agents, new homes
            developers, property managing companies, property owners, who pay to advertise their
            properties across our website and apps.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            We want to help you find your next home, and we want to make sure you can do so safely
            and securely. This is why we carry out checks on all of our customers before we allow
            them to advertise on TryBuy.club.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            <strong>TIP:</strong> You can search for all agents and property professionals that
            advertise their properties for sale or rent on TryBuy.club.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            TryBuy.club is not an estate agent, but instead the place where tens of thousands of
            property professionals choose to market their properties.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            If you ever come across a property advert on another website or platform that says it is
            being marketed by a company using the TryBuy.club name (for example, TryBuy.club Estate
            Agents), then it will not be genuine or from us. Please always be careful when looking
            for properties on other parts of the internet and follow the advice below.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">How to Stay Safe Online:</h2>
          <p className="text-lg lg:text-xl mb-4">
            Though the vast majority of people want to help you find your next home, despite our
            best efforts there will always be a small number of fraudsters out there who want to try
            and trick you into giving up some of your personal details or parting with money.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">
            Common Ways Fraudsters May Try to Trick You:
          </h2>
          <p className="text-lg lg:text-xl mb-4">
            With advance fee scams, a fraudster will often offer a highly desirable property in a
            popular location that is well below what you would normally pay for a similar property
            in that area. They may try and trick you into giving them money by setting up a fake
            page of another well-known property or lettings website. In some cases, these pages
            could have a padlock in the top left of the page, so they could look convincing. They
            may even set up a fake instant chat with you on one of these fake web pages and even
            provide you with a fake invoice (see guidance on phishing pages below).
          </p>
          <p className="text-lg lg:text-xl mb-4">
            You should never agree to send money using Bitcoin or other cryptocurrencies. When asked
            to make a bank transfer payment, double check that the name on that account matches the
            person or company you have been interacting with.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Fraudsters may try to use the TryBuy.club name on other websites or by getting in
            contact with you to make you think that they work for TryBuy.club. If you see a property
            on another website that mentions TryBuy.club, contact us immediately.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Phishing Emails:</h2>
          <p className="text-lg lg:text-xl mb-4">
            'Phishing' is when criminals use scam emails, SMS or chat messages, phone calls, or
            social media to trick their victims. Their goal is often to make you visit a website,
            which may download a virus onto your computer or steal bank details or other personal
            information. We will only send you emails from addresses with the following email
            domains: @trybuy.club.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            A common phishing email may ask you to click on a link that takes you through to a fake
            webpage to fill in some personal details. One way to check if the website is genuine is
            to look in the top left corner of your browser.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            For reference, the TryBuy.club website looks like this on a computer and like this on a
            mobile or tablet. If you are on a page that appears to be TryBuy.club but does not have
            an address that starts with this, please contact us straight away.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">
            How to Spot Fake Adverts, Emails, or Text Messages:
          </h2>
          <p className="text-lg lg:text-xl mb-4">
            We recommend familiarizing yourself with the key ways you can spot fake or rogue
            adverts, emails, or text messages. Often a fraudster will say they have a property to
            sell or rent that is much cheaper than others you may have looked at in the same area.
            Here are some quick checks you can do:
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>
              Do a search of that property address on Google Maps and see if the result matches the
              images on the listing
            </li>
          </ul>
          <p className="text-lg lg:text-xl mb-4">
            We have a specialist team who are constantly monitoring our site for fake listings. Here
            are some of the common characteristics of a fake advert.
          </p>
          <ul className="list-disc ml-8 mb-4">
            <li>
              A line of text in the images of the listings asking the home-hunter to contact an
              email address for quicker responses
            </li>
            <li>
              A few properties uploaded by the same agent in a short space of time and all of these
              properties are in various locations
            </li>
            <li>
              A property is advertised in an area that is a large distance away from where the
              agent's branch is located, if it’s a local estate agent (rather than an online or
              national agent)
            </li>
          </ul>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">
            How to Spot Fake Emails, Websites, and Links:
          </h2>
          <p className="text-lg lg:text-xl mb-4">
            Check the email address of the sender or the website’s link. Fraudulent email addresses
            and web pages are designed to look legitimate but have small differences, such as
            including random characters in the website address link (numbers, letters or other
            characters such as &,%,$ or ï¿½). In some cases, the differences in the spelling of a
            company name can be extremely subtle, or may just include a few extra characters at the
            beginning. As an example, a fraudster may try to replace the ‘y’ in ‘TryBuy.club’ with a
            ‘i’ so that the website address was TriBuy.club, instead of TryBuy.club. If you’re ever
            in any doubt, you can type the website address into your search bar to go straight to a
            company’s website rather than clicking on a link.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Be wary of emails that contain links that you are asked to click on. This could be
            sending you to a fake website they have set up and may ask you to log-in with your
            personal details. Only click a link or open attachments if it comes from a sender that
            you trust. If you are required to provide personal information to an agent or landlord
            you could do this in person or verbally over the phone, making sure you are speaking to
            that agent using their official branch contact number advertised on TryBuy.club.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            Avoid requests that sound vague or urgent. Fraudsters often introduce an element of
            urgency into their communications – this is intended to cause people to shortcut their
            decision making process and not carry out the checks described on this page. For this
            reason it’s especially important that to be wary of emails that ask for urgent action.
          </p>
          <p className="text-lg lg:text-xl mb-4">
            You can find out more about the costs associated with renting or buying in our property
            guides centre.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Other Types of Fraud:</h2>
          <p className="text-lg lg:text-xl mb-4">
            Fraudsters may use other communication methods such as fake text messages or phones
            calls and in some cases you could be at risk of malware or other viruses, especially if
            they ask you to click on attachments.
          </p>
          <h2 className="text-2xl lg:text-3xl font-semibold mb-6">Getting in Touch:</h2>
          <p className="text-lg lg:text-xl mb-4">
            If you think you may have been a victim of a scam or you’re unsure and want some help,
            you can email us at .......................................
          </p>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageSecurityTips_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageSecurityTips;
