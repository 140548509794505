import axios from "axios";

export default async function getListingsForUser(limit: number, userId: number) {
  try {
    const listings = await axios.get(`/listing/listings/user?userId=${userId}&limit=${limit}`, {
      headers: { Authorization: localStorage.getItem("token") },
    });
    if (listings.data.properties.length === 0) {
      return null;
    }
    return listings;
  } catch (error: any) {
    throw new Error(error);
  }
}
