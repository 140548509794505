import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";

export interface PageTrainingResourcesProps {
  className?: string;
}

const PageTrainingResources: FC<PageTrainingResourcesProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageTrainingResources overflow-hidden relative ${className}`}
      data-nc-id="PageTrainingResources"
    >
      <Helmet>
        <title>Training Resources - TryBuy.club</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="py-16 lg:py-28 space-y-8 lg:space-y-12">
          <h2 className="text-3xl lg:text-4xl font-semibold text-gray-900">
            At TryBuy.club, we're committed to providing high-quality training resources to help
            real estate professionals excel in their careers. Explore our comprehensive range of
            educational materials designed to enhance your skills and knowledge in the real estate
            industry.
          </h2>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Work and Live Anywhere
            </h3>
            <p>
              In today's interconnected world, we recognize that traditional office boundaries no
              longer define where or how work gets done. That's why we offer our partners the
              freedom to work from anywhere in the world, within the bounds of local regulations.
              Whether you prefer the hustle and bustle of urban life or the tranquility of a rural
              setting, you'll find the flexibility you need to thrive.
            </p>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Create Meaningful Impact
            </h3>
            <p>
              Join our global creative community, where passion and collaboration fuel innovation
              that shapes the world. By working alongside industry-leading talent, you'll have the
              opportunity to make a real difference and leave your mark on the real estate
              landscape. Our vibrant network aims to encompass individuals from all walks of life,
              united by a shared commitment to excellence and a drive to succeed.
            </p>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              Unlock Your Next Opportunity with TryBuy.club
            </h3>
            <ul className="list-disc list-inside">
              <li>
                Real Estate Agents and Brokers: Connect with motivated buyers and sellers, and
                showcase your expertise to a global audience.
              </li>
              <li>
                Real Estate Agencies: Expand your reach and grow your business by accessing our
                platform's extensive network of clients and partners.
              </li>
              <li>
                Property Management Companies: Streamline your operations and attract new clients by
                leveraging our cutting-edge tools and resources.
              </li>
              <li>
                Photographers: Showcase properties in their best light with stunning photography
                that captures the imagination of potential buyers.
              </li>
              <li>
                Designers: Transform spaces and create unforgettable experiences that inspire and
                delight homeowners and buyers alike.
              </li>
              <li>
                Decorators: Elevate interiors with curated design solutions that enhance
                functionality, aesthetics, and overall appeal.
              </li>
            </ul>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <h3 className="text-2xl lg:text-3xl font-semibold text-gray-900">
              What You'll Receive from TryBuy.club
            </h3>
            <ul className="list-disc list-inside">
              <li>
                Exclusive Access to Training Materials: Gain access to our library of training
                materials, including webinars, courses, and tutorials, designed to enhance your
                skills and knowledge in the real estate industry.
              </li>
              <li>
                Expert Guidance and Support: Learn from industry experts and receive personalized
                guidance and support to help you succeed in your real estate career.
              </li>
              <li>
                Flexible Learning Options: Enjoy the flexibility of learning at your own pace,
                anytime and anywhere, with our online training resources.
              </li>
              <li>
                Continuous Updates and New Content: Stay up-to-date with the latest trends and
                developments in the real estate industry through our regularly updated training
                materials.
              </li>
            </ul>
          </div>

          <div className="space-y-8 lg:space-y-12">
            <p>
              Take your real estate career to the next level with TryBuy.club's training resources!
            </p>
          </div>
        </div>

        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageTrainingResources_" />
        </div>

        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageTrainingResources;
